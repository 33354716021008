import ServerTierBase from "./ServerTierBase";

type Props = {
  showColor: boolean,
  showDescription: boolean,
  tailText: string,
}

function ServerTierColorOrange(props: Props) {
  return (
    ServerTierBase({
      color: ServerTierColorOrange.color,
      colorName: "Laranja",
      description: ServerTierColorOrange.description,
      showColor: props.showColor,
      showDescription: props.showDescription,
      tailText: props.tailText
    })
  );
}

ServerTierColorOrange.description = "Local possivelmente perigoso e que pode ter infestações de zumbis e/ou Bots armados com porretes"
ServerTierColorOrange.color = "#F59300"

ServerTierColorOrange.defaultProps = {
  showColor: true,
  showDescription: false,
  tailText: "",
}

export default ServerTierColorOrange;