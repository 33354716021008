import React from 'react';
import {ItemPresenter} from "../ItemPresenter";

const className = "cowsteakmeat";

function GICowSteakMeat() {
  return (
    <ItemPresenter imagePath={`/images/gameItems/${className}.png`} tooltipText={className} />
  );
}

export default GICowSteakMeat;