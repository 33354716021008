import React from 'react';

type Props = {
  color: string,
  colorName: string,
  description: string,
  showColor: boolean,
  showDescription: boolean,
  tailText: string,
}

function ServerTierBase(props: Props) {
  return (
    <div>
      <label style={{color: props.color, fontWeight: "bold"}} hidden={! props.showColor}>{props.colorName}</label>
      <label style={{color: props.color}} hidden={(! props.showColor || ! props.showDescription)}>:</label>
      <label hidden={! props.showDescription}> {props.description}{props.tailText}</label>
    </div>
  );
}

export default ServerTierBase;