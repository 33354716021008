import React, {useContext, useEffect, useState} from 'react';
import Title from "antd/es/typography/Title";
import {useNavigate} from "react-router-dom";
import {BackendContext} from "../../../App";
import {Paths} from "../../../Paths";
import ClearBody from "../../../components/ClearBody";
import {Button, Modal, notification, Spin} from "antd";
import {Messages} from "../../../backend/Messages";
import {StatusCodes} from "../../../backend/StatusCodes";

type NotificationType = 'success' | 'info' | 'warning' | 'error';


function RebootServerPage() {
  const backendContext = useContext(BackendContext);
  const [api, contextHolder] = notification.useNotification();
  const navigate = useNavigate();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const openNotificationWithIcon = (type: NotificationType, title: string, description: string) => {
    api[type]({
      message: title,
      description: description,
    });
  };

  useEffect(() => {
    if (! backendContext.hasPlayerAuth()) {
      navigate(Paths.LoginSteamPage);
    }
  }, [backendContext, navigate]);

  const clickedRebootServer = () => {
    setIsModalOpen(true);
    backendContext.triggerRebootServer("Aplicar mudanças no servidor W").then((commonResponse) => {
      setIsModalOpen(false);
      console.log("Error: " + commonResponse.isError);
      if (commonResponse.isError) {
        openNotificationWithIcon("error", "Error", commonResponse.description);
        return;
      }

      openNotificationWithIcon("success", "Success", commonResponse.description);

    }).catch((error) => {

      setIsModalOpen(false);
      openNotificationWithIcon("error", Messages.UnknownErrorTitle, Messages.UnknownErrorDescription + " " + JSON.stringify(error));
    });
  }

  return (
    <>
      {contextHolder}
      <ClearBody content={
        <div>
          <Title>Reboot server</Title>
          <br />
          <Button type="primary" onClick={clickedRebootServer}>Trigger reboot</Button>

          <Modal open={isModalOpen} centered={true} closable={false}
                 okButtonProps={{ style: { display: 'none' } }}
                 cancelButtonProps={{ style: { display: 'none' } }}
          >
            <div style={{textAlign: "center"}}>
              <Spin size="large" />
            </div>
          </Modal>

        </div>
      } />
    </>
  );
}

export default RebootServerPage;