import React from 'react';
import DonateItemPresenter from "./DonateItemPresenter";
import {Typography} from "antd";

function ExpansionTractor() {
  return (
    <DonateItemPresenter
      itemName={"Cator"}
      images={[
        "expansiontractor/red",
        "expansiontractor/green",
        "expansiontractor/blue",
      ]}
      trunk={"70 (7 x 10)"}
      seats={"1"}
      insurance={"Sim, consulte a seção de seguros para mais informações"}
      price={<Typography.Title code>R$ 3,00</Typography.Title>}
    />
  );
}

export default ExpansionTractor;