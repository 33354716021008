import React from 'react';
import {Image} from "antd";
import {Link} from "react-router-dom";
import {Paths} from "../../Paths";

const steamLogoDivStyle: React.CSSProperties = {
  marginTop: "11px",
  background: "#e7e7e7",
  textAlign: "center",
  height: "42px",
  width: "42px",
  borderRadius: "16px",
};

const steamLogoImageStyle: React.CSSProperties = {
  marginBottom: "25px",
}

function SteamHeader() {
  return (
    <Link to={Paths.LoginSteamPage} >
      <div style={steamLogoDivStyle}>
        <Image src={`/images/steam/shortIcon.png`} height={36} width={36} preview={false}
          style={steamLogoImageStyle} />
      </div>
    </Link>
    // </div>
  );
}

export default SteamHeader;