import React from 'react';
import {Breadcrumb, Divider, Image, Typography} from "antd";
import Title from "antd/es/typography/Title";
import Paragraph from "antd/es/typography/Paragraph";
import ClearBody from "../../../components/ClearBody";
import {Paths} from "../../../Paths";
import {Link} from "react-router-dom";

const contentWidth = 1000;

function GuideBeginnerPage() {
  return (
    <ClearBody content={
      <div>
        <Breadcrumb style={{ margin: '16px 0' }}
          items={[
            {
              title: <Link to={Paths.GuidesPage}>Guias</Link>
            },
            {
              title: "Para iniciantes"
            }
          ]}
        />

        <Title>Guia para iniciantes</Title>

        <Typography.Title level={3}>
          <li>Sistema bancário / dinheiro inicial</li>
        </Typography.Title>
        <div>
          <div style={{float: "left", marginLeft: 32, marginRight: 16, marginBottom: 16}}>
            <Image width={256} src={"/images/atm.png"}  />
          </div>
          <Typography.Title level={5} style={{width: contentWidth}}>
          Todo player começa com $120.000,00 de saldo em conta. Utilize este dinheiro para se equipar inicialmente. Nos negociadores em Green mountain tem caixas eletrônicos, utilize-os para acessar sua conta bancária. <br />
          </Typography.Title>
        </div>
        <Divider />

        <Typography.Title level={3}>
          <li>Zumbis mais agressivos</li>
        </Typography.Title>
        <Typography.Title level={5} style={{marginLeft: 32, width: contentWidth}}>
          No nosso server temos zumbis modificados para prover uma experiência de jogo mais emocionante :). Dessa forma
          em Chernarus existem zumbis mais resistentes que os zumbis comuns vanilla, cuidado com eles, são mais difíceis de
          matar e batem mais forte. <br />
          Também são alterados alguns comportamentos nos zumbis para fazer a experiência de sobrevivência mais desafiadora:
          <ul>
            <li>Caso o jogador suba em algum obstáculo para fugir dos zumbis, os zumbis começarão a jogar pedras no player. Essas pedras tonteiam o player e dão um pouquinho de dano.</li>
            <li>Caso o jogador se esconda atrás de uma porta e o zumbi foi aggrado, ele começará a bater na porta e depois de algum tempo quebrará a porta. </li>
            <li>Ao atropelar zumbis com carros, isso irá danificar o radiador do carro, então a partir de agora comecem a desviar dos zumbis ou o carro irá pro pau.</li>
            <li>Cuidado ao ficar dentro do carro próximo de algum zumbi aggrado, pois se o zumbi não conseguir chegar no jogador ele começará a destruir o veículo.</li>
          </ul>
        </Typography.Title>
        <Divider />

        <Typography.Title level={3}>
          <li>Traders</li>
        </Typography.Title>
        <Typography.Title level={5} style={{marginLeft: 32, width: contentWidth}}>
          Atualmente temos vários traders espalhados por Chernarus, comprando e vendendo diferentes itens. O Trader mais importante para iniciantes são os Traders de Green Mountain.
          Nesse local temos diversos traders individuais que vendem equipamentos básicos para auxiliar na sobrevivência. Para mais informações sobre os traders, acesse: <Link to={Paths.GuidesTradersPage}>Traders</Link>.
        </Typography.Title>
        <Divider />

        <Typography.Title level={3}>
          <li>Veículos</li>
        </Typography.Title>
        <Typography.Title level={5} style={{marginLeft: 32, width: contentWidth}}>
          Veículos são o meio de transporte fundamental para se locomover em Chernarus. No nosso servidor veículos não spawnam no mapa e portanto devem ser adquiridos nos vendedores de veículos.
          Estes vendedores estão separados em 2 tipos de vendedores: 1) vendedor de veículos básicos e simples que fica em Green Mountain e 2) vendedor de veículos especiais, sendo maiores, com mais slots e
          mais estilosos. Um ponto importante é que todos os veículos tem um tempo máximo para ficar no mapa sem interação de players por perto e quando esse limite for atingido, os veículos serão deletados do mapa.
          Portanto fique atento nesse tempo de inatividade e cuide do seu veículo. <br />
          Outro ponto importante é que por segurança os veículos devem ser trancados e para tal o player deve comprar uma chave que é vendida geralmente ao lado de onde se compra o carro, e vincular essa chave
          ao veículo. Também é importante mencionar que por segurança faça cópias da sua chave, pois se perdê-la, não será possível acessar o veículo mais. Para mais informações, acesse:
          <Link to={Paths.GuidesVehiclesPage}>Veículos</Link>.
        </Typography.Title>
        <Divider />

        <Typography.Title level={3}>
          <li>Reputação</li>
        </Typography.Title>
        <div style={{float: "left", marginLeft: 32, marginBottom: 8, marginRight: 16, marginTop: 22}}>
          <Image width={64} src={"/images/reputation.png"}  />
        </div>
        <Typography.Title level={5} style={{width: contentWidth}}>
          As estrelas que ficam à direita da vida no painel de status é a quantidade de reputação que o player tem no servidor.
          Essa reputação é utilizada para acesso a Traders específicos e também serve como moéda de compra para certos itens.
          Reputação é ganha de acordo com ações feitas dentro do jogo, como por exemplo matar zumbis, Bots, animais, fazer quests & etc.
          Para mais informações, acesse: <Link to={Paths.GuidesReputationPage}>Reputação</Link>.
        </Typography.Title>
        <Divider />

        <Typography.Title level={3}>
          <li>Bots</li>
        </Typography.Title>
        <div>
          <div style={{float: "left", marginLeft: 32, marginRight: 16, marginBottom: 16}}>
            <Image width={256} src={"/images/bots.png"}  />
          </div>
          <Typography.Title level={5} style={{width: contentWidth}}>
            No servidor temos Bots pelo mapa, esses bots estão sempre vestidos de NBC vermelha e esses Bots são hostis, portanto ao vê-los eles irão
            tentar matá-lo, então defenda-se! O NBC vermelho é uma forma de fazermos uma distinção entre players e Bots para que não haja confusões
            entre quem é Player e quem é Bot, portanto nunca se vista de NBC vermelho pois players podem achar que você é Bot e matá-lo. Nesta circunstância
            o player que matou não será banido pois não tinha como saber que alguém que se veste como Bot na verdade é um Player. Para mais informações,
            acesse: <Link to={Paths.GuidesBotsPage}>Bots</Link>.
          </Typography.Title>
        </div>
        <Divider />

        <Typography.Title level={3}>
          <li>Personal storage (baú)</li>
        </Typography.Title>
        <div>
          <div style={{float: "left", marginLeft: 32, marginRight: 16, marginBottom: 16}}>
            <Image width={256} src={"/images/beginners/personalStorage.png"}  />
          </div>
          <Typography.Title level={5} style={{width: contentWidth}}>
            O baú (personal storage) que está disponível em alguns lugares é um local onde você pode guardar seus itens
            de forma segura, o que é muito útil principalmente no início da sua jornada quando você não possui ainda o 'seu cantinho'
            para guardar suas coisas. Utilize o baú, é gratuito!
          </Typography.Title>
        </div>
        <Divider />

        <Typography.Title level={3}>
          <li>Atalhos úteis no teclado</li>
        </Typography.Title>
        <Typography.Title level={5} style={{marginLeft: 32, width: contentWidth}}>
          [<label style={{fontWeight: "bold"}}>Z</label>] - Faz com que seu personagem continue correndo sem que você precise ficar apertando o teclado. <br />
          [<label style={{fontWeight: "bold"}}>P</label>] - Fone de ouvido que diminui bastante o volume do jogo. Útil quando se está em veículos barulhentos. <br />
          [<label style={{fontWeight: "bold"}}>,</label>] - Alterna entre os chats disponívels no jogo e altera quem enxerga as mensagens que você manda [Global, Proximidade, Time]. <br />
        </Typography.Title>
      </div>
    } />
  );
}

export default GuideBeginnerPage;