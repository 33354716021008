import React from 'react';
import DonateItemPresenter from "./DonateItemPresenter";
import {Typography} from "antd";

function ExpansionUH1H() {
  return (
    <DonateItemPresenter
      itemName={"[Exp] UH-1H"}
      images={[
        "expansionuh1h/green",
        "expansionuh1h/civilian",
        "expansionuh1h/medical",
        "expansionuh1h/resistence",
        "expansionuh1h/uber",
      ]}
      trunk={"500 (10 x 50)"}
      seats={"6"}
      insurance={"Sim, consulte a seção de seguros para mais informações"}
      price={<Typography.Title code>R$ 110,00</Typography.Title>}
    />
  );
}

export default ExpansionUH1H;