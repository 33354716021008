import React from 'react';
import {ItemPresenter} from "../ItemPresenter";

function AnimalWolf() {
  return (
    <ItemPresenter imagePath={"/images/animals/wolf.png"} bottomLabel={"Lobo"}
      customImageWidth={" "}
      />
  );
}

export default AnimalWolf;