import React from 'react';
import {ItemPresenter} from "../ItemPresenter";

const className = "bearpelt";

function GIBearPelt() {
  return (
    <ItemPresenter imagePath={`/images/gameItems/${className}.png`} tooltipText={className} />
  );
}

export default GIBearPelt;