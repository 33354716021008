import React from 'react';
import {ItemPresenter} from "../ItemPresenter";

const className = "wolfsteakmeat";

function GIWolfSteakMeat() {
  return (
    <ItemPresenter imagePath={`/images/gameItems/${className}.png`} tooltipText={className} />
  );
}

export default GIWolfSteakMeat;