import React from 'react';
import ServerTierBase from "./ServerTierBase";

type Props = {
  showColor: boolean,
  showDescription: boolean,
  tailText: string,
}

function ServerTierColorRed(props: Props) {
  return (
    ServerTierBase({
      color: ServerTierColorRed.color,
      colorName: "Vermelho",
      description: ServerTierColorRed.description,
      showColor: props.showColor,
      showDescription: props.showDescription,
      tailText: props.tailText
    })
  );
}

ServerTierColorRed.description = "Local perigoso e que pode ter a presença de Bots armados com arma de fogo e/ou o Nemesis (sim, o do RE3)"
ServerTierColorRed.color = "#f50000"

ServerTierColorRed.defaultProps = {
  showColor: true,
  showDescription: false,
  tailText: "",
}

export default ServerTierColorRed;