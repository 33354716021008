import MapHandlyDefinedInfo from "./model/MapHandlyDefinedInfo";
import {MapInstance} from "./MapInstance";

export class MapHandlyDefinitions {

    static getDefinitionForMap(map: MapInstance): MapHandlyDefinedInfo | null {
      switch (map) {
        case MapInstance.CHERNARUS_PLUS:
          return {
            latMin: -240,
            latMax: 0,
            lngMin: 0,
            lngMax: 240,
          }

        default:
          return null;
      }
    }

}