import React, {useContext, useEffect, useState} from 'react';
import Title from "antd/es/typography/Title";
import {useNavigate} from "react-router-dom";
import {BackendContext} from "../../../App";
import {Paths} from "../../../Paths";
import ClearBody from "../../../components/ClearBody";
import {Button, Modal, notification, Spin} from "antd";
import {Messages} from "../../../backend/Messages";
import {StatusCodes} from "../../../backend/StatusCodes";

type NotificationType = 'success' | 'info' | 'warning' | 'error';


function RepairBagPage() {
  const backendContext = useContext(BackendContext);
  const [api, contextHolder] = notification.useNotification();
  const navigate = useNavigate();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const openNotificationWithIcon = (type: NotificationType, title: string, description: string) => {
    api[type]({
      message: title,
      description: description,
    });
  };

  useEffect(() => {
    if (! backendContext.hasPlayerAuth()) {
      navigate(Paths.LoginSteamPage);
    }
  }, [backendContext, navigate]);

  const clickedStartRepair = () => {
    setIsModalOpen(true);
    backendContext.repairMyBag().then((commonResponse) => {
      setIsModalOpen(false);
      console.log("Error: " + commonResponse.isError);
      if (commonResponse.isError) {
        const translations = StatusCodes.parseCode(commonResponse.statusCode);
        openNotificationWithIcon("error", translations.title, translations.description);
        return;
      }

      openNotificationWithIcon("success", "Sucesso", "A bag foi reparada ;)");

    }).catch((error) => {

      setIsModalOpen(false);
      openNotificationWithIcon("error", Messages.UnknownErrorTitle, Messages.UnknownErrorDescription + " " + JSON.stringify(error));
    });
  }


  return (
    <>
      {contextHolder}
      <ClearBody content={
        <div>
          <Title>Reparar bolsa donate</Title>
          <br />

          <Title level={4}>Para reparar sua bag donate, note:</Title>
          <ol>
            <li>O processo de reparo é transformar o estado de uma bag donate de 'destruído' para 'muito danificado'.</li>
            <li>A bag muito danificada pode ser reparada de 'muito danificada' para 'impecável' usando fita adesiva ou kit de costura.</li>
            <li>Para fazer o reparo via esta ferramenta, é preciso que você esteja com a mochila donate destruída sendo segurada nas mãos do seu player e o seu player deve estar online no servidor.</li>
            <li>Para que o processo seja bem sucedido você deve ter um vale reparo dentro da mochila sendo segurada e o vale reparo não pode estar destruído.</li>
            <li>O vale reparo deve estar diretamente dentro da mochila segurada e não pode estar dentro de um container que esteja dentro da mochila.</li>
            <li>O vale reparo pode ser adquirido no quadro de quests na Green Mountain.</li>
          </ol>
          <br />
          <Title level={4}>Com todos os itens acima em mente, inicie o processo de reparo clicando no botão:</Title>
          <Button type="primary" onClick={clickedStartRepair}>Reparar mochila donate</Button>

          <Modal open={isModalOpen} centered={true} closable={false}
                 okButtonProps={{ style: { display: 'none' } }}
                 cancelButtonProps={{ style: { display: 'none' } }}
          >
            <div style={{textAlign: "center"}}>
              <Spin size="large" />
            </div>
          </Modal>

        </div>
      } />
    </>
  );
}

export default RepairBagPage;