import React from 'react';
import DonateItemPresenter from "./DonateItemPresenter";
import {Typography} from "antd";

function ExpansionLandRover() {
  return (
    <DonateItemPresenter
      itemName={"M1025"}
      images={[
        "offroad_02/green",
      ]}
      trunk={"300 (10 x 30)"}
      seats={"4"}
      specialSlots={
        <ul>
          <li>Estepe</li>
        </ul>
      }
      insurance={"Sim, consulte a seção de seguros para mais informações"}
      price={<Typography.Title code>R$ 5,00</Typography.Title>}
    />
  );
}

export default ExpansionLandRover;