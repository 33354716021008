import React from 'react';
import {Breadcrumb, Divider, Typography} from "antd";
import Title from "antd/es/typography/Title";
import ClearBody from "../../../components/ClearBody";
import {Paths} from "../../../Paths";
import {Link} from "react-router-dom";

function GuideBeginnerPage() {
  return (
    <ClearBody content={
      <div>
        <Breadcrumb style={{ margin: '16px 0' }}
          items={[
            {
              title: <Link to={Paths.GuidesPage}>Guias</Link>
            },
            {
              title: "Como fazer dinheiro no servidor"
            }
          ]}
        />
        <Title>Como fazer dinheiro no servidor</Title>

        <Typography.Title level={5} style={{marginLeft: 32}}>
          Fazer dinheiro é muito necessário, não é mesmo? Precisamos de equipamentos melhores e armas mais potentes, bem como ter veículos e equipar nossa base! <br />
          Abaixo segue a lista de atividades que pode render uma boa grana para o player:
        </Typography.Title>

        <ol>
          <Typography.Title level={3}>
            <li>Caça</li>
          </Typography.Title>
          <Typography.Title level={5}>
            Caçar é uma forma muito rentável de se fazer dinheiro. Ao se matar animais e esquartejá-los, vários itens são obtidos e podem ser vendidos para os caçadores. <br />
            Tais itens que os caçadores possuem interesse e compram:
            <ul>
              <li>Cabeças dos animais</li>
              <li>Peles</li>
              <li>Carnes</li>
              <li>Gorduras</li>
              <li>Entranhas</li>
            </ul>
            Dê um pulo nos caçadores e veja em detalhes o que pode ser vendido. Caçar é uma atividade que pode ser feita mesmo pelos jogadores mais iniciantes. <br />
            <Typography.Text type={"danger"}>
              Ao caçar é extremamente importante prestar atenção onde a caça será atingida pelos tiros. Tiros no corpo estragam o couro obtido e diminui o número de carnes geradas durante o esquartejamento.
              Para evitar esse problema, acerte os tiros na cabeça da caça.
            </Typography.Text>
          </Typography.Title>
          <Divider />

          <Typography.Title level={3}>
            <li>Trabalhar couro</li>
          </Typography.Title>
          <Typography.Title level={5}>
            Trabalhar couro é uma forma de se fazer dinheiro com alguns itens obtidos durante a caça. As peles obtidas podem ser vendidas e fazer dinheiro, porém essas mesmas peles
            podem ser transformadas em couro e se fazer ainda mais dinheiro (embora com um pouquinho mais de trabalho). Veja detalhes do processo nesse Link: <Link to={Paths.GuidesLeatherTanningPage}>Trabalhando o couro</Link>. <br />
            É possível fazer essa atividade de trabalhar o couro ser ainda mais lucrativa ao se costurar o couro em peças feita de couro, como por exemplo Luvas e Sapatos, entre outros, que
            também podem ser vendidos para os caçadores. Como transformar couro em peças de couro também é explicado no guia acima mencionado. <br />
            <Typography.Text type={"danger"}>
              Importante notar que a qualidade do couro/produto de couro afeta diretamente o preço que o Caçador pagará, de forma que uma peça 'Impecável' vale mais do que uma 'Gasta' ou 'Danificada'.
              Para tentar evitar essa perda, misture couro 'Danificado' com couro 'Impecável' de forma que o resultado dessa mistura seja um couro melhorado. Essa mistura é feita ao
              se arrastar uma pilha de couro em cima de outra no inventário, assim como é feito com outros itens stackáveis (impilháveis).
            </Typography.Text>
          </Typography.Title>
          <Divider />

          <Typography.Title level={3}>
            <li>Quests</li>
          </Typography.Title>
          <Typography.Title level={5}>
            Quests disponibilizadas por alguns NPC's pagam um valor bacana, seja quests de matar bots, de coletar itens ou de escoltar NPCs. Dê uma olhada nos NPCs
            que disponibilizam quests!
          </Typography.Title>
          <Divider />

          <Typography.Title level={3}>
            <li>AirDrops</li>
          </Typography.Title>
          <Typography.Title level={5}>
            AirDrops que ocorrem de tempos em tempos no servidor podem conter diferentes tipos de recompensas, bem como diferentes
            tipos de unidades protegendo o AirDrop. Em caso de zumbis, podem conter loots valiosos em seus bolsos, em caso de animais, itens que dão um bom dinheiro
            nos caçadores, e caso sejam Bots, podem estar carregando dinheiro e metais preciosos, bem como suas armas dão uma boa grana, sem contar o loot da caixa
            principal, é claro! É sempre bom ficar de olho nos AirDrops no servidor!
          </Typography.Title>
          <Divider />

          <Typography.Title level={3}>
            <li>Eliminação de Bots</li>
          </Typography.Title>
          <Typography.Title level={5}>
            Matar Bots pode ser perigoso, mas também altamente lucrativo caso seja um bom jogador. Bots carregam dinheiro e metais preciosos, bem como armas e munições
            que quando vendidas dão uma incrementada boa nos ganhos da empreitada. Seja Bots de Quests, Bots andando pelo mapa ou Bots defendendo posições específicas sempre
            será uma ótima fonte de renda caso o jogador consiga sair com vida depois do confronto :-).
          </Typography.Title>
          <Divider />

          <Typography.Title level={3}>
            <li>Plantação de verduras</li>
          </Typography.Title>
          <Typography.Title level={5}>
            Está cansado de emoção e quer dar uma descansada? Mas claro, não sem ver os digitos na conta bancária aumentando? Plantar verduras pode ser uma opção
            lucrativa pra quem tem paciência. Dê uma olhada em como plantar em <Link to={Paths.GuidesFarmingPage}>Plantando verduras</Link>. <br /> Essas verduras podem ser vendidas nas Vendinhas
            espalhadas por Chernarus, fazendo assim um bom dinheiro! É planejado adicionar Quests para entregar essas verduras nas Vendinhas por um preço ainda melhor, mas isso depende de implementação ainda.
          </Typography.Title>
          <Divider />

          <Typography.Title level={3}>
            <li>Plantação e produção de narcóticos</li>
          </Typography.Title>
          <Typography.Title level={5}>
            (A ser implementado)
          </Typography.Title>
          <Divider />

          <Typography.Title level={3}>
            <li>Loot genérico</li>
          </Typography.Title>
          <Typography.Title level={5}>
            Como sempre, pode ser uma opção passear por Chernarus juntando armas e itens valiosos para vender nos Traders! Chernarus é muito bonita.
          </Typography.Title>
          <Divider />
        </ol>
      </div>
    } />
  );
}

export default GuideBeginnerPage;