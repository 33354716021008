import React from 'react';
import DonateItemPresenter from "./DonateItemPresenter";
import {Typography} from "antd";

function ExpansionGyrocopter() {
  return (
    <DonateItemPresenter
      itemName={"[Exp] Gyrocopter Single"}
      images={[
        "expansiongyrocopter",
      ]}
      trunk={"10 (5 x 2)"}
      seats={"1"}
      insurance={"Sim, consulte a seção de seguros para mais informações"}
      price={<Typography.Title code>R$ 3,00</Typography.Title>}
    />
  );
}

export default ExpansionGyrocopter;