import React, {useContext, useEffect, useState} from 'react';
import Title from "antd/es/typography/Title";
import {useNavigate} from "react-router-dom";
import {BackendContext} from "../../../App";
import {Paths} from "../../../Paths";
import ClearBody from "../../../components/ClearBody";
import {Button, Modal, Spin} from "antd";
import {Table} from "antd/lib";
import { RedoOutlined } from '@ant-design/icons';
import PlayingPlayerRepresentationDTO from "../../../backend/models/PlayingPlayerRepresentationDTO";

function InGamePlayerDetailsPage() {
  const backendContext = useContext(BackendContext);
  const navigate = useNavigate();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [players, setPlayers] = useState<Array<PlayingPlayerRepresentationDTO>>([]);

  useEffect(() => {
    if (! backendContext.hasPlayerAuth()) {
      navigate(Paths.LoginSteamPage);
    }
  }, [backendContext, navigate]);

  const clickedUpdatePlayers = () => {
    setIsModalOpen(true);
    backendContext.getPlayingPlayers().then((response) => {
      setIsModalOpen(false);
      setPlayers(response.playingPlayers);

    }).catch((error) => {
      console.error(error);
      setIsModalOpen(false);
    });
  }

  const columns = [
    {
      title: 'Profile',
      dataIndex: 'profileName',
      key: 'profileName',
    },
    {
      title: 'SteamID',
      dataIndex: 'steamID',
      key: 'steamID',
    },
    {
      title: 'PosX',
      dataIndex: 'positionX',
      key: 'positionX',
    },
    {
      title: 'PosY',
      dataIndex: 'positionY',
      key: 'positionY',
    },
    {
      title: 'PosZ',
      dataIndex: 'positionZ',
      key: 'positionZ',
    },
    {
      title: 'BohemianID',
      dataIndex: 'bohemianID',
      key: 'bohemianID',
    }
  ];

  return (
    <>
      <ClearBody content={
        <div>
          <Title>In game player details</Title>
          <br />

          <Button type="primary" onClick={clickedUpdatePlayers} icon={<RedoOutlined />} />
          <Table columns={columns} dataSource={players}/>

          <br />
          <br />

          <Modal open={isModalOpen} centered={true} closable={false}
                 okButtonProps={{ style: { display: 'none' } }}
                 cancelButtonProps={{ style: { display: 'none' } }}
          >
            <div style={{textAlign: "center"}}>
              <Spin size="large" />
            </div>
          </Modal>

        </div>
      } />
    </>
  );
}

export default InGamePlayerDetailsPage;