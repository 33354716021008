import React, {useCallback, useContext, useEffect, useRef, useState} from 'react';
import Title from "antd/es/typography/Title";
import {useNavigate} from "react-router-dom";
import {BackendContext} from "../../../App";
import {Paths} from "../../../Paths";
import ClearBody from "../../../components/ClearBody";
import {Button, Divider, Spin, Switch} from "antd";
import {MapContainer, Marker, Popup, TileLayer, useMapEvents, Tooltip} from "react-leaflet";
import {CRS, LatLngBounds, Icon} from "leaflet";
import {MapUtils} from "../../../maps/MapUtils";
import MapInfo from "../../../maps/model/MapInfo";
import {MapInstance} from "../../../maps/MapInstance";
import {MapHandlyDefinitions} from "../../../maps/MapHandlyDefinitions";
import MapCalculatedInfo from "../../../maps/model/MapCalculatedInfo";
import MapHandlyDefinedInfo from "../../../maps/model/MapHandlyDefinedInfo";
import PlayingPlayerRepresentationDTO from "../../../backend/models/PlayingPlayerRepresentationDTO";
import {RedoOutlined} from "@ant-design/icons";
import {IconUser} from "../../../components/icons/maps/IconUser";
import CleanTooltip from "../../../components/maps/CleanTooltip";
import Card from "antd/lib/card";

const map = MapInstance.CHERNARUS_PLUS;
const mapHandlyDefinition: MapHandlyDefinedInfo = MapHandlyDefinitions.getDefinitionForMap(map)!!;


function ChildrenComponent() {
  const map = useMapEvents({
    click: (event) => {
      console.log(`Clicked point: [, ${event.latlng.lat}, ${event.latlng.lng}`);
    },
  });
  return null
}

var scheduleRunning = false;

function MapViewPage() {
  const backendContext = useContext(BackendContext);
  const navigate = useNavigate();

  const mapRef = useRef(null)

  const [mapInfo, setMapInfo] = useState<MapInfo | null>(null);
  const [mapCalculatedInfo, setMapCalculatedInfo] = useState<MapCalculatedInfo | null>(null);
  const [playingPlayers, setPlayingPlayers] = useState<Array<PlayingPlayerRepresentationDTO>>([]);

  //
  const [isAutoUpdating, setIsAutoUpdating] = useState<boolean>(true);
  const isAutoUpdatingRef = useRef(isAutoUpdating);
  const [playersEnabled, setPlayersEnabled] = useState<boolean>(true);
  const playersEnabledRef = useRef(playersEnabled);
  const [playersShowingName, setPlayersShowingName] = useState<boolean>(true);
  //

  const scheduleNextUpdateTick = useCallback(() => {
    if (! scheduleRunning) {
      console.log("Scheduling");
      scheduleRunning = true;
      setTimeout(updateTick, 5000);
    }
  }, []);

  const updateTick = useCallback(() => {
    console.log("Tick");
    scheduleRunning = false;

    const promises: Array<Promise<any>> = [];
    if (isAutoUpdatingRef.current) {
      if (playersEnabledRef.current) {
        const playersPromise = backendContext.getPlayingPlayers();
        promises.push(playersPromise);
        playersPromise.then((response) => {
          /*response.playingPlayers.push({
            positionX: 5000,
            positionY: 90,
            positionZ: 5000,
            profileName: "Test player",
            steamID: "1234",
            bohemianID: "abcde",
          });*/
          setPlayingPlayers(response.playingPlayers);

        }).catch((error) => {
          console.error(error);
        });
      }
    }

    if (promises.length > 0) {
      Promise.all(promises).then(() => {
        console.log("All promises resolved for schedule");
        scheduleNextUpdateTick();
      }).catch((error) => {
        console.log("All promises failed for schedule");
        scheduleNextUpdateTick();
        console.error(error);
      });
    } else {
      scheduleNextUpdateTick();
    }
  }, [backendContext, scheduleNextUpdateTick]);

  useEffect(() => {
    isAutoUpdatingRef.current = isAutoUpdating;

    if (! backendContext.hasPlayerAuth()) {
      navigate(Paths.LoginSteamPage);
    }

    if (! mapInfo) {
      MapUtils.getMapInfo(map).then((mapInfo) => {
        setMapInfo(mapInfo);
        setMapCalculatedInfo(MapUtils.calculateMapInfo(mapInfo, mapHandlyDefinition));
      }).catch(reason => {
        console.error("Error when fetchint map info: ", reason);
      });
    }

    if (! scheduleRunning) {
      updateTick();
    }
  }, [backendContext, navigate, mapRef, mapInfo, updateTick, isAutoUpdating]);

  return (
    <>
      <ClearBody content={
        <div>
          <Title>Map viewing</Title>
          <br />

          {mapInfo ? (
            <div>
              <Card title="Player"
                extra={
                  <Switch
                    checked={playersEnabled}
                    checkedChildren="Showing players"
                    unCheckedChildren="Not showing players"
                    onChange={(checked) => {
                      setPlayersEnabled(checked);
                      playersEnabledRef.current = checked;
                      if (!checked) {
                        setPlayingPlayers([]);
                      }
                    }}
                  />
                }
              >
                <Switch
                  checked={playersShowingName}
                  checkedChildren="Player names: On"
                  unCheckedChildren="Player names: Off"
                  onChange={(checked) => {
                    setPlayersShowingName(checked);
                  }}
                />
              </Card>
              <br />
              <div style={{textAlign: "center"}}>
                <Switch
                  checked={isAutoUpdating}
                  checkedChildren="Auto update activated (every 1 second)"
                  unCheckedChildren="Auto update disabled"
                  onChange={(checked) => {
                    setIsAutoUpdating(checked);
                  }}
                />
              </div>
              <Divider />
              <MapContainer ref={mapRef}
                            crs={CRS.Simple}
                            center={[mapInfo?.worldSize / 2, mapInfo?.worldSize / 2]}
                            zoom={2}
                            maxZoom={mapInfo.maxZoom}
                            minZoom={mapInfo.minZoom}
                            scrollWheelZoom={true}
                            style={{height: 700}}
                            bounds={new LatLngBounds({lat: mapHandlyDefinition?.latMin ?? 0, lng: mapHandlyDefinition?.lngMin ?? 0}, {lat: mapHandlyDefinition?.latMax ?? 0, lng: mapHandlyDefinition?.lngMax ?? 0})}
                            maxBounds={new LatLngBounds({lat: mapHandlyDefinition?.latMin ?? 0, lng: mapHandlyDefinition?.lngMin ?? 0}, {lat: mapHandlyDefinition?.latMax ?? 0, lng: mapHandlyDefinition?.lngMax ?? 0})}

              >
                <ChildrenComponent />
                <TileLayer url={MapUtils.getMapAddress(map)}
                           noWrap={true}
                />
                {playingPlayers.map((player) =>
                  <Marker
                    key={player.steamID}
                    position={[
                      MapUtils.projectCoordToLat(player.positionZ ?? 0, mapHandlyDefinition, mapCalculatedInfo!),
                      MapUtils.projectCoordToLng(player.positionX ?? 0, mapHandlyDefinition, mapCalculatedInfo!),
                    ]}
                    title={player.profileName}
                    icon={IconUser.getIcon()}
                >
                    {playersShowingName ?
                      <CleanTooltip
                        content={player.profileName}
                      /> : null
                    }
                </Marker>
              )}
              </MapContainer>
            </div>

            ) : (

            <div style={{textAlign: "center"}}>
              <Spin size="large" />
            </div>
          )}




          <br />
          <br />
          {/*<Button type="primary" onClick={clickedSetNewValues}>Set new values</Button>*/}

        </div>
      } />
    </>
  );
}

function convertNumericToLatLng(x: number, z: number): {lat: number, lng: number} {
  // Define the conversion factor. This value depends on the scale of your map.
  const conversionFactor = 100;

  // Convert lat/lng to simple numeric coordinates.
  const lat = x * conversionFactor;
  const lng = z * conversionFactor;

  return {lat, lng};
}

export default MapViewPage;