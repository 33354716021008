import {Icon} from "leaflet";

export class IconUser {
  static getIcon(): Icon {
    return new Icon({
      iconUrl: '/images/icons/user.png',
      iconSize: [30, 30],
      myString: 'This is my string',
    });
  }
}