import React from 'react';
import ClearBody from "../../../../components/ClearBody";
import {Image, Typography} from "antd";
import Paragraph from "antd/es/typography/Paragraph";
import {Link} from "react-router-dom";
import {Paths} from "../../../../Paths";

const bodyStyle: React.CSSProperties = {
  textAlign: "center",
  marginTop: "128px",
}

function SteamLoginPage() {
  const currentDomain = window.location.origin;
  const returnPage = `${currentDomain}${Paths.LoginSteamReturnPage}`

  const steamLoginUrl = "https://steamcommunity.com/openid/login?" +
    "openid.ns=http://specs.openid.net/auth/2.0&" +
    "openid.claimed_id=http://specs.openid.net/auth/2.0/identifier_select&" +
    "openid.identity=http://specs.openid.net/auth/2.0/identifier_select&" +
    "openid.mode=checkid_setup&" +
    // `openid.realm=https://example.com&` +
    `openid.realm=${currentDomain}&` +
    "openid.return_to=" + returnPage

  return (
    <ClearBody content={
      <div style={bodyStyle}>
        <Link to={steamLoginUrl}>
          <Image src={`/images/steam/logginButton.png`} preview={false}/>
        </Link>
        <Paragraph>
          <Typography.Title level={5}>
            Ao realizar login com sua conta Steam, poderemos indentificar qual player você é no servidor e isso permitirá
            acessar funcionalidades especiais no servidor ;)
          </Typography.Title>
        </Paragraph>
      </div>
    } />
  );
}

function startSteamLogin() {

}

export default SteamLoginPage;