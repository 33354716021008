import React from 'react';
import {ItemPresenter} from "../ItemPresenter";

function AnimalDeerFemale() {
  return (
    <ItemPresenter imagePath={"/images/animals/deerFemale.png"} bottomLabel={"Veado fêmea"}
      customImageWidth={" "}
      />
  );
}

export default AnimalDeerFemale;