import React, {ReactComponentElement} from 'react';
import {Space, Typography} from "antd";

type Props = {
  item: ReactComponentElement<any>,
  quantityRange: QuantityRange | undefined,
  quantityExact: QuantityExact | undefined,
}

type QuantityRange = {
  min: number,
  max: number,
}

type QuantityExact = {
  quantity: number,
}

const textStyle: React.CSSProperties = {
  margin: "auto"
}

function ItemQuantityDisplay(props: Props) {
  let quantity;
  if (props.quantityRange !== undefined) {
    quantity = <Typography.Title level={4} style={textStyle}>{props.quantityRange.min} ~ {props.quantityRange.max} x </Typography.Title>
  } else if (props.quantityExact !== undefined) {
    quantity = <Typography.Title level={4} style={textStyle}>{props.quantityExact.quantity} x </Typography.Title>
  } else {
    quantity = <label>?</label>
  }

  return (
    <Space>
      {quantity}
      {props.item}
    </Space>
  );
}

ItemQuantityDisplay.defaultProps = {
  quantityRange: undefined,
  quantityExact: undefined,
}

export default ItemQuantityDisplay;
