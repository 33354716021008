export class Messages {
  static UnknownErrorTitle = "Erro desconhecido";
  static UnknownErrorDescription = "Por favor repita a operação e se o erro persistir, entre em contato com o suporte";

  static UnauthorizedRequestTitle = "Sem permissão";
  static UnauthorizedRequestDescription = "Você não tem permissão para fazer esta requisição";
  static PlayerNotOnlineTitle = "Erro";
  static PlayerNotOnlineDescription = "Você não está online no servidor";
  static NothingBeingHeldOnHandsTitle = "Erro";
  static NothingBeingHeldOnHandsDescription = "Você não está segurando nada nas mãos";
  static NoVipBagOnHandsTitle = "Erro";
  static NoVipBagOnHandsDescription = "Você não está segurando uma Bag donate";
  static VipBagNotDestroyedTitle = "Erro";
  static VipBagNotDestroyedDescription = "A bag donate segurada não está destruída";
  static NoValidRepairVoucherFoundTitle = "Erro";
  static NoValidRepairVoucherFoundDescription = "Nenhum voucher de reparo válido encontrado";
}