import React, {useContext} from 'react';
import {Image, Tooltip, Typography} from "antd";
import Utils from "../utils/Utils";
import {ItemShowCasingStylingContext} from "../App";


type Props = {
  tooltipText: string,
  imagePath: string,
  bottomLabel: string,
  customImageWidth: string,
}

type ItemPresenterStyleConfigs = {
  imagePreview: boolean | undefined,
  imageWidth: string,
}

function ItemPresenter(props: Props) {
  const stylingContext = useContext(ItemShowCasingStylingContext)
  return (
    <div>
      <Tooltip title={props.tooltipText}>
        <Image hidden={Utils.isEmptyString(props.imagePath)} src={props.imagePath} preview={stylingContext.itemPresenterStyleConfigs.imagePreview}
               width={ ! Utils.isEmptyString(props.customImageWidth) ? props.customImageWidth : stylingContext.itemPresenterStyleConfigs.imageWidth}
        />
      </Tooltip>
       <br />
      <Typography.Text hidden={Utils.isEmptyString(props.bottomLabel)}>{props.bottomLabel}</Typography.Text>
    </div>
  );
}

ItemPresenter.defaultProps = {
  tooltipText: "",
  imagePath: "",
  bottomLabel: "",
  customImageWidth: "",
}

export {ItemPresenter};
export type { ItemPresenterStyleConfigs };

