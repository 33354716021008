import React from 'react';
import DonateItemPresenter from "./DonateItemPresenter";
import {Typography} from "antd";

function ExpansionMh6() {
  return (
    <DonateItemPresenter
      itemName={"[Exp] Mh6 Little bird"}
      images={[
        "expansionmh6/green",
        "expansionmh6/black",
        "expansionmh6/blueline",
        "expansionmh6/digital",
        "expansionmh6/elliptical",
        "expansionmh6/furious",
        "expansionmh6/graywatch",
        "expansionmh6/jeans",
        "expansionmh6/resistance",
        "expansionmh6/shadow",
        "expansionmh6/sheriff",
        "expansionmh6/speedy",
        "expansionmh6/sunset",
        "expansionmh6/vrana",
        "expansionmh6/wasp",
        "expansionmh6/wave",
      ]}
      trunk={"200 (10 x 20)"}
      seats={"4"}
      insurance={"Sim, consulte a seção de seguros para mais informações"}
      price={<Typography.Title code>R$ 80,00</Typography.Title>}
    />
  );
}

export default ExpansionMh6;