import React from 'react';
import {Breadcrumb, Divider, Image, Typography} from "antd";
import Title from "antd/es/typography/Title";
import ClearBody from "../../../components/ClearBody";
import {Paths} from "../../../Paths";
import {Link} from "react-router-dom";

function GuideBeginnerPage() {
  return (
    <ClearBody content={
      <div>
        <Breadcrumb style={{ margin: '16px 0' }}
          items={[
            {
              title: <Link to={Paths.GuidesPage}>Guias</Link>
            },
            {
              title: "Veículos"
            }
          ]}
        />
        <Title>Veículos</Title>

        <Typography.Title level={5} style={{marginLeft: 32}}>
          Veículos são o meio de transporte fundamental para se locomover em Chernarus. No nosso servidor veículos não spawnam no mapa e portanto devem ser adquiridos nos vendedores de veículos.
          Estes vendedores estão separados em 2 tipos de vendedores: 1) vendedor de veículos básicos e simples que fica em Green Mountain e 2) vendedor de veículos especiais, sendo maiores, com mais slots e
          mais estilosos. <br />
          Todos os veículos tem um tempo máximo para ficar no mapa sem interação de players por perto e quando esse limite for atingido, os veículos serão deletados do mapa.
          Portanto fique atento nesse tempo de inatividade e cuide do seu veículo. <br />
          <br />
          Há uma grande variedade de veículos especiais disponíveis no servidor, portanto não serão exibidos aqui, e em caso de curiosidade faça uma visita ao <Link to={Paths.GuidesTradersPage}>Trader de veículos especiais</Link>. <br />
          Já os veículos básicos são: <br />
          <Image src={"/images/basicCars.png"} width={"50%"} />
        </Typography.Title>

        <ol type="a">
          <Typography.Title level={3}>
            <li>Trancando seu veículo</li>
          </Typography.Title>
          <Typography.Title level={5}>
            <ol>
              <li>
                Primeiramente compre uma chave na máquina de auto-atendimento: <br />
                <Image src={"/images/vehicles/keys.png"}/>
              </li>
              <li>
                Agora com a chave em mãos olhe para o seu veículo: <br />
                <Image src={"/images/vehicles/assignKey.png"} width={"50%"}/>
                <Typography.Text type="danger" style={{marginLeft: 8, textDecoration: "underline", fontWeight: "bold"}}>
                  PS: Todas as portas e porta-malas e capô devem estar fechados para que a opção apareça!
                </Typography.Text>
              </li>
              <li>
                Pronto, seu veículo tem uma chave vinculada. Matenha seu carro sempre trancado para sua segurança e dos seus itens.
              </li>
            </ol>
          </Typography.Title>
          <Divider />

          <Typography.Title level={3}>
            <li>Fazendo uma cópia da chave</li>
          </Typography.Title>
          <Typography.Title level={5}>
            <ol>
              <li>
                Com uma chave que foi vinculada a algum veículo olhe para a lixadeira ao lado do Trader: <br />
                <Image src={"/images/vehicles/copyKey.png"} width={"50%"}/>
                <Typography.Text type="danger" style={{marginLeft: 8, textDecoration: "underline", fontWeight: "bold"}}>
                  PS: Chaves não vinculadas a algum veículo não podem ser copiadas!
                </Typography.Text>

              </li>
              <li>
                Pronto, seu veículo tem mais de uma chave vinculada. Mantenha uma cópia da chave na sua base por segurança. Vai saber o que
                pode acontecer com você, não é mesmo? 😂
              </li>
            </ol>
          </Typography.Title>
          <Divider />

          <Typography.Title level={3}>
            <li>Descapotando / destravando meu veículo</li>
          </Typography.Title>
          <Typography.Title level={5}>
            <ol>
              <li>
                Com uma chave de boca olhe para o veículo: <br />
                <Image src={"/images/vehicles/flipTool.png"} /> <br />
                <Image src={"/images/vehicles/flip.png"} width={"50%"}/>
                <Typography.Text type="danger" style={{marginLeft: 8, textDecoration: "underline", fontWeight: "bold"}}>
                </Typography.Text>
              </li>
              <li>
                Pronto, seu veículo está pronto pra outra!
              </li>
            </ol>
          </Typography.Title>
          <Divider />

          <Typography.Title level={3}>
            <li>Permissão para dirigir</li>
          </Typography.Title>
          <Typography.Title level={5}>
            Apenas o proprietário do veículo pode dirigir o veículo, ou algum outro player que esteja no mesmo grupo/party do proprietário
            ou que esteja no mesmo clã/território que o proprietário. Caso alguém que não preencha esses requisitos tente dirigir o veículo
            será kickado do servidor (não é ban, apenas kick).
          </Typography.Title>
          <Divider />
        </ol>
      </div>
    } />
  );
}

export default GuideBeginnerPage;