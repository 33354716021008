import React from 'react';
import {Breadcrumb, Divider, Image, Typography} from "antd";
import Title from "antd/es/typography/Title";
import ClearBody from "../../../components/ClearBody";
import {Paths} from "../../../Paths";
import {Link} from "react-router-dom";

function GuideBeginnerPage() {
  return (
    <ClearBody content={
      <div>
        <Breadcrumb style={{ margin: '16px 0' }}
          items={[
            {
              title: <Link to={Paths.GuidesPage}>Guias</Link>
            },
            {
              title: "Helicópteros"
            }
          ]}
        />
        <Title>Helicópteros</Title>

        <Typography.Title level={5} style={{marginLeft: 32}}>
          Helicópteros são veículos de rápido deslocamento entre localidades no servidor. No servidor eles não spawnam no mapa e devem ser adquiridos
          no <Link to={Paths.GuidesTradersPage}>Trader Companhia Dumont</Link>. <br />
          Helicópteros também são deletados após inatividade, assim como veículos normais, portanto fique de olho na inatividade do seu heli. Assim como os
          veículos os helicópteros podem e devem ser trancados para a segurança do seu heli e dos itens que estiverem nele. Os passos para vincular uma chave ao
          heli são os mesmos dos veículos. <br />
          Em caso de capotamento, é possível utilizar os mesmos passos que são utilizados para descapotar veículos, dê uma olhada <Link to={Paths.GuidesVehiclesPage}>aqui</Link>.
          As mesmas permissões para dirigir um heli que são aplicadas aos veículos também são aplicadas aos helicópteros, portanto fique atento a quem pode dirigir um helicóptero específico, em caso de dúvida, dê uma olhada <Link to={Paths.GuidesVehiclesPage}>aqui</Link>.
        </Typography.Title>

        <ul>
          <Typography.Title level={3}>
            <li>Espaço aéreo restrito</li>
          </Typography.Title>
          <Typography.Title level={5}>
            Algumas áreas não podem ser sobrevoadas e caso algum Player insista, ele será teleportado para fora da mesma. <br />
            Os seguintes espaços aéreos não podem ser sobrevoados:
            <ul>
              <li>
                Ilha Skalist
              </li>
            </ul>
          </Typography.Title>
          <Divider />
        </ul>

        <ul>
          <Typography.Title level={3}>
            <li>Paraquedas</li>
          </Typography.Title>
          <Typography.Title level={5}>
            Ao voar pode ser uma boa idéia estar equipado de paraquedas, caso aconteça algo (disconnect?) e você caia do céu, você não morrerá :) <br />
            Existem dois tipos de paraquedas que são vendidos no mesmo lugar que são vendidas as aeronaves, esses dois tipos de paraquedas são:
            <ol>
              <li>
                Paraquedas padrão: <br />
                <Image src={"/images/parachute/parachute.png"} height={400} /> <br />
                É um paraquedas que não permite acoplamento de uma mochila nele.
              </li>
              <br />
              <br />
              <li>
                Paraquedas tático: <br />
                <Image src={"/images/parachute/tactical-parachute.png"} height={400} /> <br />
                É um paraquedas avançado que permite acoplamento de uma mochila nele, portanto você estará com a sua bag nas costas
                e também estará protegido de uma queda aérea.
              </li>
            </ol>
            <br />
            Ambos os paraquedas são ativados apertando a barra de espaço enquanto estiver em queda.
          </Typography.Title>
          <Divider />
        </ul>
      </div>
    } />
  );
}

export default GuideBeginnerPage;