import React from 'react';
import {Breadcrumb, Divider, Space, Typography} from "antd";
import Title from "antd/es/typography/Title";
import ClearBody from "../../../components/ClearBody";
import {Paths} from "../../../Paths";
import {Link} from "react-router-dom";
import {Table} from "antd/lib";
import AnimalCow from "../../../components/animals/AnimalCow";
import AnimalBull from "../../../components/animals/AnimalBull";
import ItemQuantityDisplay from "../../../components/ItemQuantityDisplay";
import GICowSteakMeat from "../../../components/gameItems/GICowSteakMeat";
import GICowPelt from "../../../components/gameItems/GICowPelt";
import GIGuts from "../../../components/gameItems/GIGuts";
import GILard from "../../../components/gameItems/GILard";
import GIBone from "../../../components/gameItems/GIBone";
import AnimalHen from "../../../components/animals/AnimalHen";
import AnimalRooster from "../../../components/animals/AnimalRooster";
import GIChickenBreastMeat from "../../../components/gameItems/GIChickenBreastMeat";
import GIFeather from "../../../components/gameItems/GIFeather";
import AnimalEwe from "../../../components/animals/AnimalEwe";
import AnimalRam from "../../../components/animals/AnimalRam";
import GISheepSteakMeat from "../../../components/gameItems/GISheepSteakMeat";
import GISheepPelt from "../../../components/gameItems/GISheepPelt";
import AnimalGoat from "../../../components/animals/AnimalGoat";
import GIGoatSteakMeat from "../../../components/gameItems/GIGoatSteakMeat";
import GIGoatPelt from "../../../components/gameItems/GIGoatPelt";
import AnimalPig from "../../../components/animals/AnimalPig";
import GIPigSteakMeat from "../../../components/gameItems/GIPigSteakMeat";
import GIPigPelt from "../../../components/gameItems/GIPigPelt";
import AnimalBoar from "../../../components/animals/AnimalBoar";
import GIBoarSteakMeat from "../../../components/gameItems/GIBoarSteakMeat";
import GIWildBoarPelt from "../../../components/gameItems/GIWildBoarPelt";
import GIBoarHead from "../../../components/gameItems/GIBoarHead";
import AnimalDoe from "../../../components/animals/AnimalDoe";
import AnimalRoeBuck from "../../../components/animals/AnimalRoeBuck";
import GIDeerSteakMeat from "../../../components/gameItems/GIDeerSteakMeat";
import GIDeerPelt from "../../../components/gameItems/GIDeerPelt";
import AnimalDeerFemale from "../../../components/animals/AnimalDeerFemale";
import AnimalDeerMale from "../../../components/animals/AnimalDeerMale";
import GIDeerHeadM from "../../../components/gameItems/GIDeerHead_M";
import AnimalBear from "../../../components/animals/AnimalBear";
import GIBearHead from "../../../components/gameItems/GIBearHead";
import GIBearSteakMeat from "../../../components/gameItems/GIBearSteakMeat";
import GIBearPelt from "../../../components/gameItems/GIBearPelt";
import AnimalWolf from "../../../components/animals/AnimalWolf";
import GIWolfHead from "../../../components/gameItems/GIWolfHead";
import GIWolfSteakMeat from "../../../components/gameItems/GIWolfSteakMeat";
import GIWolfPelt from "../../../components/gameItems/GIWolfPelt";

function GuideBeginnerPage() {
  return (
    <ClearBody content={
      <div>
        <Breadcrumb style={{ margin: '16px 0' }}
          items={[
            {
              title: <Link to={Paths.GuidesPage}>Guias</Link>
            },
            {
              title: "Caçando"
            }
          ]}
        />
        <Title>Caçando</Title>
        <Typography.Title level={5} style={{marginLeft: 32}}>
          Caçar animais pode ser uma experiência muito agradável pois os desenvolvedores do DayZ colocaram muito esforço em implementar um mecanismo muito real
          com atenção a muitos detalhes. Seguir o som ou as pegadas de um cervo é muito prazeroso para aqueles que gostam de caçar animais. <br />
          Quando caçando tome cuidado com onde seus tiros acertarão, pois se os tiros acertarem no corpo da caça isso irá estragar o couro e a quantidade de carnes
          obtidas ao esquartejar a caça. <br />
          Fora a experiência, caçar pode trazer bons retornos financeiros se o jogador estiver preparado e souber trabalhar o que for obtido após esquartejar a caça. <br />
        </Typography.Title>
        <Divider />

        <Typography.Title level={3}>
          <li>Animais para caçar</li>
        </Typography.Title>
        <Table pagination={false} bordered
          columns={[
           {
             title: "Animal",
             dataIndex: "animal",
             key: "animal"
           },
           {
             title: "Loot",
             dataIndex: "loot",
             key: "loot"
           },
            {
              title: "Onde encontrar",
              dataIndex: "location",
              key: "location"
            }
          ]}
          dataSource={[
            {
              animal: <Space><AnimalCow /> <AnimalBull /> </Space>,
              loot:
                <div style={{display: "table"}}>
                  <ItemQuantityDisplay item={<GICowSteakMeat />} quantityRange={{min: 15, max: 20}} /> <br />
                  <ItemQuantityDisplay item={<GICowPelt />} quantityExact={{quantity: 1}} /> <br />
                  <ItemQuantityDisplay item={<GIGuts />} quantityRange={{min: 3, max: 5}} /> <br />
                  <ItemQuantityDisplay item={<GILard />} quantityRange={{min: 2, max: 3}} /> <br />
                  <ItemQuantityDisplay item={<GIBone />} quantityRange={{min: 3, max: 4}} /> <br />
                </div>,
              location:
                <div>
                  <Typography.Title level={4}>
                    <ul>
                      <li>Campos</li>
                      <li>Próximo a lagos</li>
                      <li>Dentro ou fora de currais</li>
                    </ul>
                  </Typography.Title>
                </div>,
             key: "cowbull"
            },
            {
              animal: <Space><AnimalHen /> <AnimalRooster /> </Space>,
              loot:
                <div style={{display: "table"}}>
                  <ItemQuantityDisplay item={<GIChickenBreastMeat />} quantityRange={{min: 1, max: 2}} /> <br />
                  <ItemQuantityDisplay item={<GIFeather />} quantityRange={{min: 5, max: 10}} /> <br />
                  <ItemQuantityDisplay item={<GIBone />} quantityRange={{min: 1, max: 2}} /> <br />
                </div>,
              location:
                <div>
                  <Typography.Title level={4}>
                    <ul>
                      <li>Campos</li>
                      <li>Cidades</li>
                      <li>Vilarejos</li>
                    </ul>
                  </Typography.Title>
                </div>,
              key: "henrooster"
            },
            {
              animal: <Space><AnimalEwe /> <AnimalRam /> </Space>,
              loot:
                <div style={{display: "table"}}>
                  <ItemQuantityDisplay item={<GISheepSteakMeat />} quantityRange={{min: 7, max: 13}} /> <br />
                  <ItemQuantityDisplay item={<GISheepPelt />} quantityExact={{quantity: 1}} /> <br />
                  <ItemQuantityDisplay item={<GIGuts />} quantityRange={{min: 0, max: 1}} /> <br />
                  <ItemQuantityDisplay item={<GILard />} quantityRange={{min: 0, max: 8}} /> <br />
                  <ItemQuantityDisplay item={<GIBone />} quantityRange={{min: 2, max: 4}} /> <br />
                </div>,
              location:
                <div>
                  <Typography.Title level={4}>
                    <ul>
                      <li>Campos</li>
                    </ul>
                  </Typography.Title>
                </div>,
              key: "eweram"
            },
            {
              animal: <Space><AnimalGoat /> </Space>,
              loot:
                <div style={{display: "table"}}>
                  <ItemQuantityDisplay item={<GIGoatSteakMeat />} quantityRange={{min: 5, max: 10}} /> <br />
                  <ItemQuantityDisplay item={<GIGoatPelt />} quantityExact={{quantity: 1}} /> <br />
                  <ItemQuantityDisplay item={<GIGuts />} quantityExact={{quantity: 1}} /> <br />
                  <ItemQuantityDisplay item={<GILard />} quantityRange={{min: 0, max: 6}} /> <br />
                  <ItemQuantityDisplay item={<GIBone />} quantityExact={{quantity: 1}} /> <br />
                </div>,
              location:
                <div>
                  <Typography.Title level={4}>
                    <ul>
                      <li>Campos</li>
                      <li>Fazendas</li>
                      <li>Vilarejos</li>
                    </ul>
                  </Typography.Title>
                </div>,
              key: "goat"
            },
            {
              animal: <Space><AnimalPig /> </Space>,
              loot:
                <div style={{display: "table"}}>
                  <ItemQuantityDisplay item={<GIPigSteakMeat />} quantityRange={{min: 7, max: 14}} /> <br />
                  <ItemQuantityDisplay item={<GIPigPelt />} quantityExact={{quantity: 1}} /> <br />
                  <ItemQuantityDisplay item={<GIGuts />} quantityRange={{min: 1, max: 2}} /> <br />
                  <ItemQuantityDisplay item={<GILard />} quantityExact={{quantity: 1}} /> <br />
                  <ItemQuantityDisplay item={<GIBone />} quantityExact={{quantity: 2}} /> <br />
                </div>,
              location:
                <div>
                  <Typography.Title level={4}>
                    <ul>
                      <li>Campos</li>
                      <li>Vilarejos</li>
                    </ul>
                  </Typography.Title>
                </div>,
              key: "pig"
            },
            {
              animal: <Space><AnimalBoar /> </Space>,
              loot:
                <div style={{display: "table"}}>
                  <ItemQuantityDisplay item={<GIBoarHead />} quantityExact={{quantity: 1}} /> <br />
                  <ItemQuantityDisplay item={<GIBoarSteakMeat />} quantityRange={{min: 7, max: 13}} /> <br />
                  <ItemQuantityDisplay item={<GIWildBoarPelt />} quantityExact={{quantity: 1}} /> <br />
                  <ItemQuantityDisplay item={<GIGuts />} quantityRange={{min: 0, max: 2}} /> <br />
                  <ItemQuantityDisplay item={<GILard />} quantityExact={{quantity: 1}} /> <br />
                  <ItemQuantityDisplay item={<GIBone />} quantityRange={{min: 1, max: 2}} /> <br />
                </div>,
              location:
                <div>
                  <Typography.Title level={4}>
                    <ul>
                      <li>Florestas</li>
                    </ul>
                  </Typography.Title>
                </div>,
              key: "boar"
            },
            {
              animal: <Space><AnimalDoe /> <AnimalRoeBuck /> </Space>,
              loot:
                <div style={{display: "table"}}>
                  <ItemQuantityDisplay item={<GIDeerSteakMeat />} quantityRange={{min: 3, max: 10}} /> <br />
                  <ItemQuantityDisplay item={<GIDeerPelt />} quantityExact={{quantity: 1}} /> <br />
                  <ItemQuantityDisplay item={<GIGuts />} quantityRange={{min: 2, max: 3}} /> <br />
                  <ItemQuantityDisplay item={<GILard />} quantityRange={{min: 2, max: 3}} /> <br />
                  <ItemQuantityDisplay item={<GIBone />} quantityRange={{min: 2, max: 3}} /> <br />
                </div>,
              location:
                <div>
                  <Typography.Title level={4}>
                    <ul>
                      <li>Florestas</li>
                      <li>Campos</li>
                    </ul>
                  </Typography.Title>
                </div>,
              key: "doeroebuck"
            },
            {
              animal: <Space><AnimalDeerFemale /> <AnimalDeerMale /> </Space>,
              loot:
                <div style={{display: "table"}}>
                  <ItemQuantityDisplay item={<GIDeerHeadM />} quantityExact={{quantity: 1}} /> <br />
                  <ItemQuantityDisplay item={<GIDeerSteakMeat />} quantityRange={{min: 13, max: 17}} /> <br />
                  <ItemQuantityDisplay item={<GIDeerPelt />} quantityExact={{quantity: 1}} /> <br />
                  <ItemQuantityDisplay item={<GIGuts />} quantityRange={{min: 2, max: 3}} /> <br />
                  <ItemQuantityDisplay item={<GILard />} quantityRange={{min: 2, max: 3}} /> <br />
                  <ItemQuantityDisplay item={<GIBone />} quantityRange={{min: 2, max: 3}} /> <br />
                </div>,
              location:
                <div>
                  <Typography.Title level={4}>
                    <ul>
                      <li>Florestas</li>
                      <li>Campos</li>
                    </ul>
                  </Typography.Title>
                </div>,
              key: "deers"
            },
            {
              animal: <Space><AnimalBear /> </Space>,
              loot:
                <div style={{display: "table"}}>
                  <ItemQuantityDisplay item={<GIBearHead />} quantityExact={{quantity: 1}} /> <br />
                  <ItemQuantityDisplay item={<GIBearSteakMeat />} quantityRange={{min: 10, max: 15}} /> <br />
                  <ItemQuantityDisplay item={<GIBearPelt />} quantityExact={{quantity: 2}} /> <br />
                  <ItemQuantityDisplay item={<GIGuts />} quantityRange={{min: 4, max: 6}} /> <br />
                  <ItemQuantityDisplay item={<GILard />} quantityRange={{min: 2, max: 3}} /> <br />
                  <ItemQuantityDisplay item={<GIBone />} quantityRange={{min: 3, max: 5}} /> <br />
                </div>,
              location:
                <div>
                  <Typography.Title level={4}>
                    <ul>
                      <li>Florestas</li>
                    </ul>
                  </Typography.Title>
                </div>,
              key: "bear"
            },
            {
              animal: <Space><AnimalWolf /> </Space>,
              loot:
                <div>
                  <ItemQuantityDisplay item={<GIWolfHead />} quantityExact={{quantity: 1}} /> <br />
                  <ItemQuantityDisplay item={<GIWolfSteakMeat />} quantityRange={{min: 5, max: 10}} /> <br />
                  <ItemQuantityDisplay item={<GIWolfPelt />} quantityExact={{quantity: 1}} /> <br />
                  <ItemQuantityDisplay item={<GIGuts />} quantityRange={{min: 1, max: 2}} /> <br />
                  <ItemQuantityDisplay item={<GILard />} quantityExact={{quantity: 1}} /> <br />
                  <ItemQuantityDisplay item={<GIBone />} quantityExact={{quantity: 1}} /> <br />
                </div>,
              location:
                <div>
                  <Typography.Title level={4}>
                    <ul>
                      <li>Florestas</li>
                    </ul>
                  </Typography.Title>
                </div>,
              key: "wolf"
            }
          ]} />
      </div>
    } />
  );
}

export default GuideBeginnerPage;