import React from 'react';
import ClearBody from "../../components/ClearBody";
import {Divider, Typography} from 'antd';
import Title from "antd/es/typography/Title";
import {Col, Collapse, CollapseProps, QRCode, Row} from "antd/lib";
import ZedsGigaBag from "../../components/donate/ZedsGigaBag";
import ExpansionGyrocopter from "../../components/donate/ExpansionGyrocopter";
import ExpansionBigGyrocopter from "../../components/donate/ExpansionBigGyrocopter";
import ExpansionMh6 from "../../components/donate/ExpansionMh6";
import ExpansionMerlin from "../../components/donate/ExpansionMerlin";
import ExpansionUH1H from "../../components/donate/ExpansionUH1H";
import ExpansionBus from "../../components/donate/ExpansionBus";
import ExpansionTractor from "../../components/donate/ExpansionTractor";
import FordRaptorScorpioManual from "../../components/donate/FordRaptorScorpioManual";
import FordRaptorScorpioAutomatic from "../../components/donate/FordRaptorScorpioAutomatic";
import ExpansionLandRover from "../../components/donate/ExpansionLandRover";
import Offroad02 from "../../components/donate/Offroad02";
import Truck01 from "../../components/donate/Truck01";
import ExpansionUaz469 from "../../components/donate/ExpansionUaz469";
import ExpansionUaz469Cargo from "../../components/donate/ExpansionUaz469Cargo";
import ExpansionVodnik from "../../components/donate/ExpansionVodnik";
import {Link} from "react-router-dom";
import ZedsHugeBag from "../../components/donate/ZedsHugeBag";
import ZedsMegaBag from "../../components/donate/ZedsMegaBag";
import ZedsBigBag from "../../components/donate/ZedsBigBag";
import ZedsBitelaBag from "../../components/donate/ZedsBitelaBag";

const items: CollapseProps['items'] = [
  /*{
    key: '1',
    label: 'VIP',
    children:
      <div style={{display: "inline-flex"}}>
        <ZedsGigaBag />
      </div>,
  },*/
  {
    key: '2',
    label: 'Mochilas',
    children:
      <Row>
        <Col xs={30} sm={30} md={30} lg={30} xl={30}>
          <ZedsBitelaBag />
        </Col>
        <Col xs={30} sm={30} md={30} lg={30} xl={30}>
          <ZedsBigBag />
        </Col>
        <Col xs={30} sm={30} md={30} lg={30} xl={30}>
          <ZedsMegaBag />
        </Col>
        <Col xs={30} sm={30} md={30} lg={30} xl={30}>
          <ZedsHugeBag />
        </Col>
        <Col xs={30} sm={30} md={30} lg={30} xl={30}>
          <ZedsGigaBag />
        </Col>
      </Row>
  },
  {
    key: '3',
    label: 'Veículos',
    children:
      <Row>
        <Col xs={30} sm={30} md={30} lg={30} xl={30}>
          <Truck01 />
        </Col>
        <Col xs={30} sm={30} md={30} lg={30} xl={30}>
          <ExpansionTractor />
        </Col>
        <Col xs={30} sm={30} md={30} lg={30} xl={30}>
          <ExpansionBus />
        </Col>
        <Col xs={30} sm={30} md={30} lg={30} xl={30}>
          <Offroad02 />
        </Col>
        <Col xs={30} sm={30} md={30} lg={30} xl={30}>
          <ExpansionUaz469 />
        </Col>
        <Col xs={30} sm={30} md={30} lg={30} xl={30}>
          <ExpansionUaz469Cargo />
        </Col>
        <Col xs={30} sm={30} md={30} lg={30} xl={30}>
          <ExpansionLandRover />
        </Col>
        <Col xs={30} sm={30} md={30} lg={30} xl={30}>
          <ExpansionVodnik />
        </Col>
        <Col xs={30} sm={30} md={30} lg={30} xl={30}>
          <FordRaptorScorpioManual />
        </Col>
        <Col xs={30} sm={30} md={30} lg={30} xl={30}>
          <FordRaptorScorpioAutomatic />
        </Col>
      </Row>
  },
  {
    key: '4',
    label: 'Helicópteros',
    children:
      <Row>
        <Col xs={30} sm={30} md={30} lg={30} xl={30}>
          <ExpansionGyrocopter />
        </Col>
        <Col xs={30} sm={30} md={30} lg={30} xl={30}>
          <ExpansionBigGyrocopter />
        </Col>
        <Col xs={30} sm={30} md={30} lg={30} xl={30}>
          <ExpansionMh6 />
        </Col>
        <Col xs={30} sm={30} md={30} lg={30} xl={30}>
          <ExpansionUH1H />
        </Col>
        <Col xs={30} sm={30} md={30} lg={30} xl={30}>
          <ExpansionMerlin />
        </Col>
      </Row>
  },
  {
    key: '5',
    label: 'Seguros',
    children:
      <div style={{display: "inline-flex"}}>
        Sendo implementado...
      </div>,
  },
]

function DonatesPage() {
  return (
    <ClearBody content={
      <div>
        <Title>Itens donate</Title>

        <Typography.Title level={5} style={{marginLeft: 32}}>
          Itens donate são itens que podem ser adquiridos via transferência PIX e que servem para apoiar o servidor! Gosta do servidor?
          Então apoie. <br />
          Abaixo é possível ver os itens Donates do servidor:
        </Typography.Title>
        <Collapse items={items} defaultActiveKey={4} />
        <Divider />
        <Typography.Title level={3} id={"direct"}>
          <li>Como adquirir itens donate</li>
        </Typography.Title>
        <Typography.Title level={5} style={{marginLeft: 32}}>
          Ajudar o server e adquirir itens Donate é bem fácil, basta fazer um pix para o QRCode abaixo e depois abrir um Ticket <Link to="https://discord.gg/BrmJcuRHJe">aqui</Link>, enviando o comprovante
          e informando os itens desejados! <br />
          <QRCode
            errorLevel="H"
            value="00020126580014br.gov.bcb.pix0136c331ab39-3286-4d86-a5f0-f1f133be600e5204000053039865802BR5920Igor Maldonado Floor6009Sao Paulo62070503***63043219"
            icon=""
          />
        </Typography.Title>


      </div>
    } />
  );
}

export default DonatesPage;