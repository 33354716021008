import React, {useContext, useState} from 'react';
import SteamLoginHeader from "./SteamLoginHeader";
import {BackendContext} from "../../App";
import SteamAuthenticatedHeader from "./SteamAuthenticatedHeader";
import PlayerAuthInfo from "../../backend/models/PlayerAuthInfo";

function SteamHeader() {
  const backendContext = useContext(BackendContext);
  const [dumbCounter, setDumbCounter] = useState(0);

  backendContext.wireNewPlayerAuthListener("steam-header", (playerAuth: PlayerAuthInfo | null) => {
    setDumbCounter(dumbCounter + 1);
  });

  let element: JSX.Element;
  if (backendContext.hasPlayerAuth()) {
    element = <SteamAuthenticatedHeader />
  } else {
    element = <SteamLoginHeader />;
  }

  return (
    element
  );
}

export default SteamHeader;