import React from 'react';
import {Breadcrumb, Button, Descriptions, Divider, Image, Typography} from "antd";
import Title from "antd/es/typography/Title";
import ClearBody from "../../../components/ClearBody";
import {Paths} from "../../../Paths";
import {Link} from "react-router-dom";
import Anchor from "antd/es/anchor/Anchor";

const handleDownload = () => {
  const fileUrl = '/files/modsPreset.html';
  const fileName = 'ZedsServerPreset.html';

  const link = document.createElement('a');
  link.href = fileUrl;
  link.download = fileName;
  link.click();
};

function GuideBeginnerPage() {
  return (
    <ClearBody content={
      <div>
        <Breadcrumb style={{ margin: '16px 0' }}
          items={[
            {
              title: <Link to={Paths.GuidesPage}>Guias</Link>
            },
            {
              title: "Conectar ao servidor via conexão direta"
            }
          ]}
        />
        <Title>Conectar ao servidor via conexão direta</Title>

        <Typography.Title level={5}>
          As vezes o servidor Zeds não aparece na listagem de servidores no Launcher do DayZ, sim, isso é bem chato e causa
          um transtorno enorme. Porém com esse tutorial, mesmo quando isso acontecer você irá conseguir jogar no servidor,
          tendo um pouquinho mais de trabalho, mas o DayZ prega suas peças na gente kkkk. <br />
          <br />
          O processo de conexão direta pode depender de 2 passos: <br />
          <Anchor
            affix={false}
            items={[
              {
                key: 'direct',
                href: '#direct',
                title: '1. Conectar diretamente ao servidor caso todos os mods necessários já estejam carregados.',
              },
              {
                key: 'modsSetup',
                href: '#modsSetup',
                title: '2. Configurar a lista de mods [caso seja necessário]',
              },
            ]}
          />
        </Typography.Title>


        <Typography.Title level={3} id={"direct"}>
          <li>Conectando diretamente ao servidor Zeds</li>
        </Typography.Title>

        <Typography.Title level={5}>
          Abra o DZLauncher: <br />
          <Image src={"/images/directConnection/dzlauncher.png"} width={512}/> <br />
          <Divider />

          Clique em <label style={{textDecoration: "underline"}}>PLAY / JOGAR</label> no Launcher: <br />
          <Image src={"/images/directConnection/directLauncherPlay.png"} width={512}/> <br />
          <Divider />

          Isso fará com que o jogo seja carregado na tela inicial. Nesta tela clique em <label style={{textDecoration: "underline"}}>CHANGE SERVER / TROCAR SERVIDOR</label>: <br />
          <Image src={"/images/directConnection/directConnectInside.png"} width={360}/> <br />
          <Divider />

          Nesta nova tela que se abrirá, clique em <label style={{textDecoration: "underline"}}>DIRECT / DIRETO</label>: <br />
          <Image src={"/images/directConnection/directConnectInsideDirectTab.png"} width={512}/> <br />
          <Divider />

          Agora nesta tela preencha as informações em 1): <br />
          <Image src={"/images/directConnection/directConnectInsideInput.png"} width={512}/> <br />
          <Divider />

          Com as seguintes informações: <br />
          <Descriptions layout={"horizontal"} bordered size={"small"} column={1} style={{width: 256}}>
            <Descriptions.Item label="IP ADDRESS" span={1}>
              177.27.236.195
            </Descriptions.Item>
            <Descriptions.Item label="PORT" span={1}>
              2302
            </Descriptions.Item>
          </Descriptions>

          E então clique em <label style={{textDecoration: "underline"}}>PLAY / JOGAR</label> e então a conexão será feita! Caso dê erro na configuração dos mods, siga o passo abaixo.
        </Typography.Title>

        <Divider />
        <br />





        <Typography.Title level={3} id={"modsSetup"}>
          <li>Configurando a lista de mods</li>
        </Typography.Title>
        <Typography.Title level={5}>
          Baixe o arquivo com a listagem de mods: <Button onClick={handleDownload}>Baixar arquivo</Button> <br />
          <label style={{textDecoration: "underline"}}>Lembre-se onde o arquivo será salvo, você precisará selecioná-lo a seguir.</label>
          <Divider />

          No DZLauncher clique na aba <label style={{textDecoration: "underline"}}>MODS</label>: <br />
          <Image src={"/images/directConnection/dzlauncherMods.png"} width={512}/> <br />
          <Divider />

          Então clique no botão <label style={{textDecoration: "underline"}}>mais</label> / <label style={{textDecoration: "underline"}}>more</label>: <br />
          <Image src={"/images/directConnection/modsMore.png"} width={720}/> <br />
          <Divider />

          Então clique no botão <label style={{textDecoration: "underline"}}>importar lista de mods de um arquivo...</label> /
          <label style={{textDecoration: "underline"}}>import list of mods from a file...</label>: <br />
          <Image src={"/images/directConnection/importMods.png"} width={720}/> <br />
          <Divider />
          Agora selecione o arquivo que você baixou no primeiro passo desse guia. <br />
          <br />
          Pronto, os mods corretos foram carregados e estão baixando ou já tinham sido baixado previamente. <br />
          Assim que os mods estiverem baixados e prontos, volte para o primeiro item deste guia e se conecte diretamente ao servidor.

        </Typography.Title>
        <Divider />

      </div>
    } />
  );
}

export default GuideBeginnerPage;