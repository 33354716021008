import React from 'react';
import {ItemPresenter} from "../ItemPresenter";

const className = "deerhead_m";

function GIDeerHead_M() {
  return (
    <ItemPresenter imagePath={`/images/gameItems/${className}.png`} tooltipText={className} />
  );
}

export default GIDeerHead_M;