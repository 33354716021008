import React from 'react';
import {Breadcrumb, Divider, Image, Typography} from "antd";
import Title from "antd/es/typography/Title";
import Paragraph from "antd/es/typography/Paragraph";
import ClearBody from "../../../components/ClearBody";
import {Paths} from "../../../Paths";
import {Link} from "react-router-dom";
import {Table} from "antd/lib";

function GuideBeginnerPage() {
  return (
    <ClearBody content={
      <div>
        <Breadcrumb style={{ margin: '16px 0' }}
          items={[
            {
              title: <Link to={Paths.GuidesPage}>Guias</Link>
            },
            {
              title: "Reputação"
            }
          ]}
        />
        <Title>Reputação</Title>
        <Image width={64} src={"/images/reputation.png"}  />

        <Typography.Title level={5}>
          As estrelas que ficam à direita da vida no painel de status é a quantidade de reputação que o player tem no servidor.
          Essa reputação é utilizada para acesso a Traders específicos e também serve como moéda de compra para certos itens.
          Reputação é ganha de acordo com ações feitas dentro do jogo, como por exemplo matar zumbis, Bots, animais, fazer quests & etc. <br />
        </Typography.Title>


        <Typography.Title level={3}>
          <li>Aumento de reputação</li>
        </Typography.Title>
        <Typography.Title level={5}>
          Nos seguintes eventos sua reputação aumentará:
          <Table pagination={false}
            columns={[
              {
                title: "Evento",
                dataIndex: "event"
              },
              {
                title: "Quantidade",
                dataIndex: "quantity"
              }
            ]}
           dataSource={[
             {
               event: "Matar zumbis normais",
               quantity: "5"
             },
             {
               event: "Matar zumbis militares e aprimorados leves",
               quantity: "10"
             },
             {
               event: "Matar zumbis aprimorados médios",
               quantity: "20"
             },
             {
               event: "Matar zumbis aprimorados pesados",
               quantity: "40"
             },
             {
               event: "Matar ursos",
               quantity: "50"
             },
             {
               event: "Matar animais não especificados anteriormente",
               quantity: "20"
             },
             {
               event: "Matar Bots",
               quantity: "100"
             },
             {
               event: "Quests",
               quantity: "Depende da quest"
             }
           ]} />
        </Typography.Title>
        <Typography.Title level={3}>
          <li>Diminuição de reputação</li>
        </Typography.Title>
        <Typography.Title level={5}>
          Nos seguintes eventos sua reputação diminuirá:
          <Table pagination={false}
            columns={[
              {
                title: "Evento",
                dataIndex: "event"
              },
              {
                title: "Quantidade",
                dataIndex: "quantity"
              }
            ]}
            dataSource={[
              {
                event: "Morte",
                quantity: "500"
              },
              {
                event: "Utilizar para comprar itens",
                quantity: "Depende do item"
              },
            ]} />
        </Typography.Title>
        <Divider />
      </div>
    } />
  );
}

export default GuideBeginnerPage;