import React from 'react';
import {ItemPresenter} from "../ItemPresenter";

function AnimalEwe() {
  return (
    <ItemPresenter imagePath={"/images/animals/ewe.png"} bottomLabel={"Ovelha"}
      customImageWidth={" "}
      />
  );
}

export default AnimalEwe;