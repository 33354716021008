import React, {useContext, useEffect, useState} from 'react';
import ClearBody from "../../../../components/ClearBody";
import {Spin, Typography} from "antd";
import {NavigateFunction, useLocation, useNavigate} from "react-router-dom";
import {BackendContext} from "../../../../App";
import {StatusCodes} from "../../../../backend/StatusCodes";
import PlayerAuthInfo from "../../../../backend/models/PlayerAuthInfo";
import BackendClient from "../../../../backend/BackendClient";
import {Paths} from "../../../../Paths";


const bodyStyle: React.CSSProperties = {
  textAlign: "center",
  marginTop: "128px",
}

function SteamLoginReturnPage() {
  const backendContext = useContext(BackendContext)

  const location = useLocation();
  const navigate = useNavigate();

  const [errorText, setErrorText] = useState("")

  useEffect(() => {
    backendContext.tryToDoLoginWithSteamReturnInfo(location.search)
      .then(response => {
        console.log(`resp: ${JSON.stringify(response)}`)
        if (response.commonResponse === null) {
          setErrorText("Erro desconhecido, repita a operação por favor");

        } else if (response.commonResponse.statusCode === StatusCodes.InvalidSteamLogin) {
          setErrorText("Login falhou, repita a operação");

        } else if (response.commonResponse.statusCode === StatusCodes.LoginSteamUnknownID) {
          setErrorText("Essa conta Steam nunca jogou no nosso Server, portanto não é possível fazer login =( Que tal dar uma jogadinha no nosso server? =]");

        } else if (response.playerAuthInfo !== null) {
          loginSucceeded(response.playerAuthInfo, backendContext, navigate);

        } else {
          setErrorText("?");
        }

      }).catch(reason => {
        setErrorText("Erro desconhecido, repita a operação");
      })
  }, [backendContext, location.search]);

  window.history.pushState({}, document.title, window.location.pathname);

  return (
    <ClearBody content={
      <div style={bodyStyle}>
        <div hidden={errorText.length > 0}>
          <Spin tip="Carregando informações" size="large">
            <div className="content" />
          </Spin>
          <br />
          <br />
        </div>
        <Typography.Text type={"danger"} hidden={errorText.length === 0}>{errorText}</Typography.Text>
      </div>
    } />
  );
}

function loginSucceeded(authInfo: PlayerAuthInfo, backendContext: BackendClient, navigate: NavigateFunction) {
  backendContext.wirePlayerAuth(authInfo)
  navigate(Paths.PlayerAreaPage);
}

export default SteamLoginReturnPage;