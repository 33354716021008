import React from 'react';
import DonateItemPresenter from "./DonateItemPresenter";
import {Typography} from "antd";

function ExpansionUaz469Cargo() {
  return (
    <DonateItemPresenter
      itemName={"UAZ 469 Cargo"}
      images={[
        "expansionuazcargo/green",
        "expansionuazcargo/black",
        "expansionuazcargo/lightgreen",
      ]}
      trunk={"450 (10 x 45)"}
      seats={"2"}
      insurance={"Sim, consulte a seção de seguros para mais informações"}
      price={<Typography.Title code>R$ 10,00</Typography.Title>}
    />
  );
}

export default ExpansionUaz469Cargo;