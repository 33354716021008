import React from 'react';
import {Breadcrumb, Divider, Image, Typography} from "antd";
import Title from "antd/es/typography/Title";
import ClearBody from "../../../components/ClearBody";
import {Paths} from "../../../Paths";
import {Link} from "react-router-dom";

function GuideBeginnerPage() {
  return (
    <ClearBody content={
      <div>
        <Breadcrumb style={{ margin: '16px 0' }}
          items={[
            {
              title: <Link to={Paths.GuidesPage}>Guias</Link>
            },
            {
              title: "Bots"
            }
          ]}
        />
        <Title>Bots</Title>

        <Typography.Title level={3}>
        </Typography.Title>
        <Typography.Title level={5}>
          No servidor temos Bots pelo mapa, esses bots estão sempre vestidos de NBC vermelha e esses Bots são hostis, portanto ao vê-los eles irão
          tentar matá-lo, então defenda-se! O NBC vermelho é uma forma de fazermos uma distinção entre players e Bots para que não haja confusões
          entre quem é Player e quem é Bot, portanto nunca se vista de NBC vermelho pois players podem achar que você é Bot e matá-lo. Nesta circunstância
          o player que matou não será banido pois não tinha como saber que alguém que se veste como Bot na verdade é um Player. <br />
          <br />
          Os bots podem utilizar várias armas diferentes mas de toda forma, sempre estarão vestidos de vermelho.
        </Typography.Title>
        <Image src={"/images/bots.png"}  />
        <Divider />
      </div>
    } />
  );
}

export default GuideBeginnerPage;