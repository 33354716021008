import React from 'react';
import {ItemPresenter} from "../ItemPresenter";

const className = "feather";

function GIFeather() {
  return (
    <ItemPresenter imagePath={`/images/gameItems/${className}.png`} tooltipText={className} />
  );
}

export default GIFeather;