import React from 'react';
import DonateItemPresenter from "./DonateItemPresenter";
import {Typography} from "antd";

function ExpansionBigGyrocopter() {
  return (
    <DonateItemPresenter
      itemName={"[Exp] Gyrocopter duplo"}
      images={[
        "expansionbiggyrocopter",
      ]}
      trunk={"10 (5 x 2)"}
      seats={"2"}
      insurance={"Sim, consulte a seção de seguros para mais informações"}
      price={<Typography.Title code>R$ 4,00</Typography.Title>}
    />
  );
}

export default ExpansionBigGyrocopter;