import React, {useContext, useEffect} from 'react';
import ClearBody from "../../components/ClearBody";
import Title from "antd/es/typography/Title";
import {BackendContext} from "../../App";
import {useNavigate} from "react-router-dom";
import {Paths} from "../../Paths";


function AdminAreaPage() {
  const backendContext = useContext(BackendContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (! backendContext.hasPlayerAuth()) {
      navigate(Paths.WelcomePage);
    }
  }, [backendContext, navigate]);

  return (
    <ClearBody content={
      <div>
        <Title>Admin Area</Title>
        <br />

        <Title level={3}>Utilities</Title>
        <ul>
          <li><a href={Paths.AdminAreaRebootServerPage}>Reboot server</a></li>
          <li><a href={Paths.AdminAreaSetRunningValues}>Set running values</a></li>
          <li><a href={Paths.AdminAreaInGamePlayerDetailsPage}>In game players view</a></li>
          <li><a href={Paths.AdminAreaMapViewPage}>Map view</a></li>
        </ul>
      </div>
    } />
  );
}

export default AdminAreaPage;