import MapInfo from "./model/MapInfo";
import axios from "axios";
import {MapInstanceUtils} from "./MapInstanceUtils";
import {MapInstance} from "./MapInstance";
import MapCalculatedInfo from "./model/MapCalculatedInfo";
import MapHandlyDefinedInfo from "./model/MapHandlyDefinedInfo";

export class MapUtils {
  // static readonly BaseMapAddress = "https://mr-guard.de/dayz-maps/chernarusplus/tiles/{z}/{x}/{y}.png";
  static readonly BaseMapAddress = "https://mr-guard.de/dayz-maps/";

  static async getMapInfo(forMap: MapInstance): Promise<MapInfo> {
    return new Promise<MapInfo>((resolve, reject) => {
      axios.get(`${this.BaseMapAddress}${MapInstanceUtils.getNameString(forMap)}/data.json`, {})
        .then((response) => resolve(response.data))
        .catch((reason) => {
          console.log(`Exception when trying to fetch data from mr guard maps: ${JSON.stringify(reason)}`)
          reject(reason);
        });
    });
  }

  static getMapAddress(forMap: MapInstance): string {
    return `${this.BaseMapAddress}${MapInstanceUtils.getNameString(forMap)}/tiles/{z}/{x}/{y}.png`;
  }

  static calculateMapInfo(mapInfo: MapInfo, handlyInfo: MapHandlyDefinedInfo): MapCalculatedInfo {
    const latRange = handlyInfo.latMax - handlyInfo.latMin;
    const lngRange = handlyInfo.lngMax - handlyInfo.lngMin;

    return {
      coordToLatScale: latRange / mapInfo.worldSize,
      coordToLngScale: lngRange / mapInfo.worldSize,
    }
  }

  static projectCoordToLat(coord: number, handlyInfo: MapHandlyDefinedInfo, calculatedInfo: MapCalculatedInfo): number {
    return calculatedInfo.coordToLatScale * coord + handlyInfo.latMin;
  }

  static projectCoordToLng(coord: number, handlyInfo: MapHandlyDefinedInfo, calculatedInfo: MapCalculatedInfo): number {
    return calculatedInfo.coordToLngScale * coord + handlyInfo.lngMin;
  }
}