import React from 'react';
import {ItemPresenter} from "../ItemPresenter";

const className = "wolfhead";

function GIWolfHead() {
  return (
    <ItemPresenter imagePath={`/images/gameItems/${className}.png`} tooltipText={className} />
  );
}

export default GIWolfHead;