import React from 'react';
import {ItemPresenter} from "../ItemPresenter";

function AnimalPig() {
  return (
    <ItemPresenter imagePath={"/images/animals/pig.png"} bottomLabel={"Porco"}
      customImageWidth={" "}
      />
  );
}

export default AnimalPig;