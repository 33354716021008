import React from 'react';
import DonateItemPresenter from "./DonateItemPresenter";
import {Typography} from "antd";

function FordRaptorScorpioManual() {
  return (
    <DonateItemPresenter
      itemName={"Ford Raptor - câmbio manual"}
      images={[
        "fordraptor_scorpio/black",
        "fordraptor_scorpio/2",
        "fordraptor_scorpio/3",
        "fordraptor_scorpio/4",
        "fordraptor_scorpio/5",
        "fordraptor_scorpio/6",
        "fordraptor_scorpio/7",
        "fordraptor_scorpio/8",
      ]}
      trunk={"500 (10 x 50)"}
      seats={"4"}
      specialSlots={
        <ul>
          <li>Estepe</li>
          <li>Galão de gasolina</li>
          <li>Baú do mar: +100 slots</li>
          <li>Barril: +150 slots</li>
          <li>Slot de arma</li>
          <li>Mochila: até + 700 slots</li>
        </ul>
      }
      totalSlots={"Até 1.450"}
      insurance={"Sim, consulte a seção de seguros para mais informações"}
      price={<Typography.Title code>R$ 85,00</Typography.Title>}
    />
  );
}

export default FordRaptorScorpioManual;