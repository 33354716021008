import React from 'react';
import {ItemPresenter} from "../ItemPresenter";

function AnimalRoeBuck() {
  return (
    <ItemPresenter imagePath={"/images/animals/roeBuck.png"} bottomLabel={"Corça macho"}
      customImageWidth={" "}
      />
  );
}

export default AnimalRoeBuck;