import React from 'react';
import DonateItemPresenter from "./DonateItemPresenter";
import {Typography} from "antd";

function ZedsBigBag() {
  return (
    <DonateItemPresenter
      itemName={"Mochila Big Zeds"}
      images={[
        "zeds_bag_assault/black",
        "zeds_bag_assault/alpine",
      ]}
      slots={"400 (10 x 40)"}
      specialSlots={
        <ul>
          <li>Arma</li>
          <li>MMG Medic Pouch</li>
          <li>Rádio</li>
          <li>Vareta luminosa</li>
          <li>Cantil</li>
        </ul>
      }
      repairing={<label>Reparável com fita adesiva e caso seja destruída, <a href='/player-area/repair-bag'> repare-a neste link </a>.</label>}
      insurance={"Ainda não é possível, então tome cuidado, caso perca a bag, perdeu."}
      price={<Typography.Title code>R$ 20,00</Typography.Title>}
    />
  );
}

export default ZedsBigBag;