import React from 'react';
import ClearBody from "../../components/ClearBody";
import Title from "antd/es/typography/Title";
import {Breadcrumb, Divider, Typography} from "antd";
import Paragraph from 'antd/es/typography/Paragraph';
import {Paths} from "../../Paths";
import {Link} from "react-router-dom";

function GuidesPage() {
  return (
    <ClearBody content={
      <div>
        <Breadcrumb style={{ margin: '16px 0' }}>
          <Breadcrumb.Item>Guias</Breadcrumb.Item>
        </Breadcrumb>
        <Title>Guias</Title>

        <Paragraph>
          <Typography.Title level={5}>
            Guias para auxiliar na sobrevivência dos players e informar sobre como as coisas funcionam no servidor.
          </Typography.Title>
        </Paragraph>
        <Divider />
        <Typography.Title level={5}>
          <ul>
            <li><Link to={Paths.GuidesBeginnersPage}>Para iniciantes</Link></li>
            <li><Link to={Paths.GuidesMapAndMarkersPage}>Mapa e marcadores</Link></li>
            <li><Link to={Paths.GuidesTradersPage}>Traders</Link></li>
            <li><Link to={Paths.GuidesVehiclesPage}>Veículos</Link></li>
            <li><Link to={Paths.GuidesHelicopterPage}>Helicópteros</Link></li>
            <li><Link to={Paths.GuidesReputationPage}>Sistema de reputação</Link></li>
            <li><Link to={Paths.GuidesBotsPage}>Bots</Link></li>
            <li><Link to={Paths.GuidesMakingMoneyPage}>Como fazer dinheiro no servidor</Link></li>
            <li><Link to={Paths.GuidesHuntingPage}>Caçando</Link></li>
            <li><Link to={Paths.GuidesLeatherTanningPage}>Trabalhando o couro</Link></li>
            <li><Link to={Paths.GuidesMetalsForgingPage}>Trabalhando metais preciosos</Link></li>
            <li><Link to={Paths.GuidesBulletCalibers}>Calibres de balas no servidor</Link></li>
            {/*<li><Link to={Paths.GuidesFarmingPage}>Plantação de verduras</Link></li>*/}
            {/*<li><Link to={Paths.GuidesBeginnersPage}>Mercado P2P</Link></li>*/}
            <li><Link to={Paths.GuidesDirectConnectionPage}>Conectar ao servidor via conexão direta</Link></li>


          </ul>
        </Typography.Title>
      </div>
    } />
  );
}

export default GuidesPage;