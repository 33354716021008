import './GuideBulletCalibers.css';
import React from 'react';
import {Breadcrumb, Divider, Image, Typography} from "antd";
import Title from "antd/es/typography/Title";
import ClearBody from "../../../components/ClearBody";
import {Paths} from "../../../Paths";
import {Link} from "react-router-dom";
import {Table} from "antd/lib";

function GuideBeginnerPage() {
  return (
    <ClearBody content={
      <div>
        <Breadcrumb style={{ margin: '16px 0' }}
          items={[
            {
              title: <Link to={Paths.GuidesPage}>Guias</Link>
            },
            {
              title: "Calibres de balas"
            }
          ]}
        />
        <Title>Calibres de balas</Title>

        <Typography.Title level={3}>
        </Typography.Title>
        <Typography.Title level={5}>
          No servidor temos as balas nos calibres vanilla do jogo, e também temos balas em calibres customizados de mods, o que proporciona armas mais
          poderosas que as padrão do jogo. <br />
          Abaixo segue uma lista com os calibres de balas: <br />
          <div id={"divita"}>abc</div>
          <Table pagination={false} id={"calibers-table"}
                 bordered={true}
                 rowClassName={(record, index) => {
                   if (record.origin === "Mod") {
                     return "mod-caliber";
                   } else {
                     return "vanilla-caliber";
                   }
                 }}
                 columns={[
                   {
                     title: "Calibre",
                     dataIndex: "caliber",
                     key: "caliber"
                   },
                   {
                     title: "Dano por tiro (aproximado)",
                     dataIndex: "damage",
                     key: "damage"
                   },
                   {
                     title: "Origem",
                     dataIndex: "origin",
                     key: "origin"
                   },
                   {
                     title: "Bala",
                     dataIndex: "image",
                     key: "image"
                   }
                 ]}
                 dataSource={[
                   {
                     key: "14.5",
                     caliber: "14.5 x 114mm",
                     damage: "3.500",
                     origin: "Mod",
                     image: <Image width={32} src={"/images/bullets/14.5.png"} />
                   },
                   {
                     key: ".50cal",
                     caliber: ".50cal BMG",
                     damage: "2.000",
                     origin: "Mod",
                     image: <Image width={32} src={"/images/bullets/50cal.png"} />
                   },
                   {
                     key: ".408",
                     caliber: ".408 CheyTac",
                     damage: "1.000",
                     origin: "Mod",
                     image: <Image width={32} src={"/images/bullets/408.png"} />
                   },
                   {
                     key: "127x55",
                     caliber: "127x55mm STs-130PT",
                     damage: "350",
                     origin: "Mod",
                     image: <Image width={32} src={"/images/bullets/127x55mm.png"} />
                   },
                   {
                     key: ".338",
                     caliber: "338 Lapua",
                     damage: "290",
                     origin: "Mod",
                     image: <Image width={32} src={"/images/bullets/338.png"} />
                   },
                   {
                     key: ".303",
                     caliber: ".303 British",
                     damage: "180",
                     origin: "Mod",
                     image: <Image width={32} src={"/images/bullets/303.png"} />
                   },
                   {
                     key: "8mm",
                     caliber: "8mm Mauser",
                     damage: "180",
                     origin: "Mod",
                     image: <Image width={32} src={"/images/bullets/8mm.png"} />
                   },
                   {
                     key: "30-06",
                     caliber: ".30-06 Springfield",
                     damage: "155",
                     origin: "Mod",
                     image: <Image width={32} src={"/images/bullets/30-06.png"} />
                   },
                   {
                     key: ".300blk",
                     caliber: ".300BLK",
                     damage: "145",
                     origin: "Mod",
                     image: <Image width={32} src={"/images/bullets/300blk.png"} />
                   },
                   {
                     key: "7.62x54",
                     caliber: "7.62x54mmR",
                     damage: "145",
                     origin: "Vanilla",
                     image: <Image width={32} src={"/images/bullets/7.62x54.png"} />
                   },
                   {
                     key: "500sw",
                     caliber: "500 SW",
                     damage: "129",
                     origin: "Mod",
                     image: <Image width={32} src={"/images/bullets/500sw.png"} />
                   },
                   {
                     key: ".308",
                     caliber: ".308 WIN",
                     damage: "120",
                     origin: "Vanilla",
                     image: <Image width={32} src={"/images/bullets/308.png"} />
                   },
                   {
                     key: ".44mag",
                     caliber: ".44 Magnums",
                     damage: "110",
                     origin: "Mod",
                     image: <Image width={32} src={"/images/bullets/44mag.png"} />
                   },
                   {
                     key: "7.62x39mm",
                     caliber: "7.62x39mm",
                     damage: "105",
                     origin: "Vanilla",
                     image: <Image width={32} src={"/images/bullets/7.62x39mm.png"} />
                   },
                   {
                     key: "5.56x45mm",
                     caliber: "5.56x45mm",
                     damage: "105",
                     origin: "Vanilla",
                     image: <Image width={32} src={"/images/bullets/5.56x45mm.png"} />
                   },
                   {
                     key: ".50ae",
                     caliber: ".50 Action Express",
                     damage: "100",
                     origin: "Mod",
                     image: <Image width={32} src={"/images/bullets/50ae.png"} />
                   },
                   {
                     key: "5.45x39mm",
                     caliber: "5.45x39mm",
                     damage: "80",
                     origin: "Vanilla",
                     image: <Image width={32} src={"/images/bullets/5.45x39mm.png"} />
                   },
                   {
                     key: "9x39mm",
                     caliber: "9x39mm",
                     damage: "75",
                     origin: "Vanilla",
                     image: <Image width={32} src={"/images/bullets/9x39mm.png"} />
                   },
                   {
                     key: ".357",
                     caliber: ".357",
                     damage: "64",
                     origin: "Vanilla",
                     image: <Image width={32} src={"/images/bullets/357.png"} />
                   },
                   {
                     key: "46x30",
                     caliber: "46x30",
                     damage: "49",
                     origin: "Mod",
                     image: <Image width={32} src={"/images/bullets/46x30.png"} />
                   },
                   {
                     key: ".45acp",
                     caliber: ".45 ACP",
                     damage: "37",
                     origin: "Vanilla",
                     image: <Image width={32} src={"/images/bullets/45acp.png"} />
                   },
                   {
                     key: "9x19mm",
                     caliber: "9x19mm",
                     damage: "37",
                     origin: "Vanilla",
                     image: <Image width={32} src={"/images/bullets/9x19mm.png"} />
                   },
                   {
                     key: ".380",
                     caliber: ".380 ACP",
                     damage: "32",
                     origin: "Vanilla",
                     image: <Image width={32} src={"/images/bullets/380.png"} />
                   },
                   {
                     key: ".22",
                     caliber: ".22 LR",
                     damage: "20",
                     origin: "Vanilla",
                     image: <Image width={32} src={"/images/bullets/22.png"} />
                   }
                 ]} />
        </Typography.Title>
        <Divider />
      </div>
    } />
  );
}

export default GuideBeginnerPage;