import React from 'react';
import {Breadcrumb, Divider, Typography} from "antd";
import Title from "antd/es/typography/Title";
import ClearBody from "../../../components/ClearBody";
import {Paths} from "../../../Paths";
import {Link} from "react-router-dom";

function GuideBeginnerPage() {
  return (
    <ClearBody content={
      <div>
        <Breadcrumb style={{ margin: '16px 0' }}
          items={[
            {
              title: <Link to={Paths.GuidesPage}>Guias</Link>
            },
            {
              title: "Plantação de verduras"
            }
          ]}
        />
        <Title>Plantação de verduras</Title>

        <Typography.Title level={5} style={{marginLeft: 32}}>
          Fazer dinheiro é muito necessário, não é mesmo? Precisamos de equipamentos melhores e armas mais potentes, bem como ter veículos e equipar nossa base! <br />
          Abaixo segue a lista de atividades que pode render uma boa grana para o player:
        </Typography.Title>

        <ol>
          <Typography.Title level={3}>
            <li>Caça</li>
          </Typography.Title>
          <Typography.Title level={5}>
            Caçar é uma forma muito rentável de se fazer dinheiro. Ao se matar animais e esquartejá-los, vários itens são obtidos e podem ser vendidos para os caçadores. <br />
            Tais itens que os caçadores possuem interesse e compram:
            <ul>
              <li>Cabeças dos animais</li>
              <li>Peles</li>
              <li>Carnes</li>
              <li>Gorduras</li>
              <li>Entranhas</li>
            </ul>
            Dê um pulo nos caçadores e veja em detalhes o que pode ser vendido. Caçar é uma atividade que pode ser feita mesmo pelos jogadores mais iniciantes. <br />
            <Typography.Text type={"danger"}>
              Ao caçar é extremamente importante prestar atenção onde a caça será atingida pelos tiros. Tiros no corpo estragam o couro obtido e diminui o número de carnes geradas durante o esquartejamento.
              Para evitar esse problema, acerte os tiros na cabeça da caça.
            </Typography.Text>
          </Typography.Title>
          <Divider />

          <Typography.Title level={3}>
            <li>Trabalhar couro</li>
          </Typography.Title>
          <Typography.Title level={5}>
            Trabalhar couro é uma forma de se fazer dinheiro com alguns itens obtidos durante a caça. As peles obtidas podem ser vendidas e obter dinheiro, porém essas mesmas peles
            podem ser transformadas em couro e se fazer ainda mais dinheiro, portanto vender a pele dá dinheiro, mas vender o couro dá ainda mais dinheiro (embora um pouquinho mais de trabalho). Ao transformar as peles
            <ul>
              <li>Cabeças dos animais</li>
              <li>Peles</li>
              <li>Carnes</li>
              <li>Gorduras</li>
              <li>Entranhas</li>
            </ul>
            Dê um pulo nos caçadores e veja em detalhes o que pode ser vendido. Caçar é uma atividade que pode ser feita mesmo pelos jogadores mais iniciantes.
          </Typography.Title>
          <Divider />
        </ol>
      </div>
    } />
  );
}

export default GuideBeginnerPage;