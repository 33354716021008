import React from 'react';
import {ItemPresenter} from "../ItemPresenter";

function AnimalCow() {
  return (
    <ItemPresenter imagePath={"/images/animals/cow.png"} bottomLabel={"Vaca"}
      customImageWidth={" "}
      />
  );
}

export default AnimalCow;