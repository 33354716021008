import React from 'react';
import {Carousel, Image} from "antd";
import Card from 'antd/lib/card';

type Props = {
  itemName: string,
  images: string[],
  slots: React.ReactNode | undefined,
  trunk: React.ReactNode | undefined,
  specialSlots: React.ReactNode | undefined,
  totalSlots: React.ReactNode | undefined,
  seats: React.ReactNode | undefined,
  specialAbilities: React.ReactNode | undefined,
  repairing: React.ReactNode | undefined,
  insurance: React.ReactNode | undefined,
  price: React.ReactNode | undefined,
}

function DonateItemPresenter(props: Props) {
  let childrenImages: any[] = []
  props.images.forEach(value => {
    childrenImages.push(<Image src={`/images/donate/${value}.png`} height={128} key={value}  />)
  })

  return (
    <div style={{width: 320, textAlign: "center", margin: "8px"}} className={"divin"}>
      <Card
        title={props.itemName}
        style={{backgroundColor: "#f8f8fa"}}
      >
        <Card.Grid style={{width: "100%"}}>
          <Carousel
            autoplay={true}
            autoplaySpeed={5000}
            lazyLoad={"ondemand"}
            speed={500}
            effect={"fade"}
            children={childrenImages}
            // style={{height: "90px"}}
            dots={false}
          />
        </Card.Grid>
        <Card.Grid hoverable={false} hidden={props.slots === undefined} style={{width: "25%", padding: "10px 2px 10px 2px"}}>
          Slots
        </Card.Grid>
        <Card.Grid hoverable={false} hidden={props.slots === undefined} style={{width: "75%", padding: "10px 2px 10px 2px"}}>
          {props.slots}
        </Card.Grid>
        <Card.Grid hoverable={false} hidden={props.trunk === undefined} style={{width: "25%", padding: "10px 2px 10px 2px"}}>
          Slots <br />porta-malas
        </Card.Grid>
        <Card.Grid hoverable={false} hidden={props.trunk === undefined} style={{width: "75%", padding: "10px 2px 10px 2px"}}>
          {props.trunk}
        </Card.Grid>
        <Card.Grid hoverable={false} hidden={props.seats === undefined} style={{width: "25%", padding: "10px 2px 10px 2px"}}>
          Assentos
        </Card.Grid>
        <Card.Grid hoverable={false} hidden={props.seats === undefined} style={{width: "75%", padding: "10px 2px 10px 2px"}}>
          {props.seats}
        </Card.Grid>
        <Card.Grid hoverable={false} hidden={props.specialSlots === undefined} style={{width: "25%", padding: "10px 2px 10px 2px"}}>
          Slots especiais
        </Card.Grid>
        <Card.Grid hoverable={false} hidden={props.specialSlots === undefined} style={{width: "75%", padding: "10px 2px 10px 2px"}}>
          {props.specialSlots}
        </Card.Grid>
        <Card.Grid hoverable={false} hidden={props.totalSlots === undefined} style={{width: "25%", padding: "10px 2px 10px 2px"}}>
          Total slots
        </Card.Grid>
        <Card.Grid hoverable={false} hidden={props.totalSlots === undefined} style={{width: "75%", padding: "10px 2px 10px 2px"}}>
          {props.totalSlots}
        </Card.Grid>
        <Card.Grid hoverable={false} hidden={props.specialAbilities === undefined} style={{width: "25%", padding: "10px 2px 10px 2px"}}>
          Habilidades especiais
        </Card.Grid>
        <Card.Grid hoverable={false} hidden={props.specialAbilities === undefined} style={{width: "75%", padding: "10px 2px 10px 2px"}}>
          {props.specialAbilities}
        </Card.Grid>
        <Card.Grid hoverable={false} hidden={props.repairing === undefined} style={{width: "25%", padding: "10px 2px 10px 2px"}}>
          Reparo
        </Card.Grid>
        <Card.Grid hoverable={false} hidden={props.repairing === undefined} style={{width: "75%", padding: "10px 2px 10px 2px"}}>
          {props.repairing}
        </Card.Grid>
        <Card.Grid hoverable={false} style={{width: "25%", padding: "10px 2px 10px 2px"}}>
          Seguro
        </Card.Grid>
        <Card.Grid hoverable={false} style={{width: "75%", padding: "10px 2px 10px 2px"}}>
          {props.insurance}
        </Card.Grid>
        <Card.Grid hoverable={false} style={{width: "100%", padding: "10px 2px 10px 2px"}}>
          {props.price}
        </Card.Grid>
      </Card>

    </div>
  );
}

DonateItemPresenter.defaultProps = {
  slots: undefined,
  trunk: undefined,
  specialSlots: undefined,
  totalSlots: undefined,
  repairing: undefined,
  seats: undefined,
  specialAbilities: undefined,
}

export default DonateItemPresenter;