import React, {useContext, useEffect, useState} from 'react';
import ClearBody from "../../components/ClearBody";
import Title from "antd/es/typography/Title";
import {Carousel, Divider, Image, Typography} from "antd";
import Paragraph from 'antd/es/typography/Paragraph';
import {ArrowUpOutlined} from "@ant-design/icons";
import {BackendContext} from "../../App";
import GameServerPublicInfo from "../../backend/models/GameServerPublicInfo";
import {Paths} from "../../Paths";
import {Link} from "react-router-dom";

function WelcomePage() {
  const backendContext = useContext(BackendContext)
  const [publicInfo, setPublicInfo] = useState(new GameServerPublicInfo())
  
  useEffect(() => {
    backendContext.getServerPublicInfo().then(value => {
      setPublicInfo(value)
    })
  }, [backendContext]);

  return (
    <div style={{marginTop: 10}}>
      <div style={{float: "left", display: "inline-grid"}}>
        <Carousel style={{width: 512}}
                  autoplay={true}
                  autoplaySpeed={5000}
                  lazyLoad={"ondemand"}
                  speed={2000}
                  effect={"fade"}
        >
          <Image src={`/images/wallpapers/1.jpg`}/>
          <Image src={`/images/wallpapers/2.jpg`}/>
          <Image src={`/images/wallpapers/3.jpg`}/>
          <Image src={`/images/wallpapers/4.jpg`}/>
          <Image src={`/images/wallpapers/5.jpg`}/>
          <Image src={`/images/wallpapers/6.jpg`}/>
          <Image src={`/images/wallpapers/7.jpg`}/>
          <Image src={`/images/wallpapers/8.jpg`}/>
          <Image src={`/images/wallpapers/9.jpg`}/>
          <Image src={`/images/wallpapers/10.jpg`}/>
          <Image src={`/images/wallpapers/11.jpg`}/>
          <Image src={`/images/wallpapers/12.jpg`}/>
          <Image src={`/images/wallpapers/13.jpg`}/>
          <Image src={`/images/wallpapers/14.jpg`}/>
          <Image src={`/images/wallpapers/15.jpg`}/>
          <Image src={`/images/wallpapers/16.jpg`}/>
          <Image src={`/images/wallpapers/17.jpg`}/>
          <Image src={`/images/wallpapers/18.jpg`}/>
        </Carousel>

        <iframe
          title={"Discord"}
          src="https://discord.com/widget?id=1120781879969317045&theme=dark"
          width="512"
          height="500"
          frameBorder="0"
          sandbox="allow-popups allow-popups-to-escape-sandbox allow-same-origin allow-scripts">
        </iframe>

        <Typography.Title style={{width: 512, verticalAlign: "top"}} level={5}>
          <ArrowUpOutlined />
          Entre no nosso Discord e faça parte da nossa comunidade
        </Typography.Title>


      </div>

      <ClearBody content={
        <div>
          <Title>
            Bem vindo ao servidor Zeds!
            <Image width={128} src={`/mstile-150x150.png`} preview={false} />
          </Title>

          <Paragraph>
            <Typography.Title level={5}>
              Somos um servidor totalmente PvE (não sendo admitido nenhum PvP) e que tem um estilo de jogo Hardcore (com emoção), portanto, não espere facilidades
              enquanto estiver tentando sobreviver em Chernarus no servidor Zeds! <br />
              Neste servidor nos preocupamos muito com a qualidade da experiência do jogo e a estabilidade do servidor, de forma que nos esforçamos duramente para
              evitar todo e qualquer crash inesperado, resultando em uma experiência maravilhosa para todos aqueles que querem passear e se divertir sozinho ou em grupo por Chernarus!
              Dessa forma o servidor só é reiniciado nos restarts programados [Detalhes] e quando mods ou o Dayz precisar atualizar.
            </Typography.Title>
          </Paragraph>
          <Divider />
          <Typography.Title level={5}>
            Para se conectar, pesquise no DayZLauncher pelo nome: <label style={{textDecoration: "underline"}}>{publicInfo.hostName}</label> <br />
            Caso esteja com dificuldades para encontrar o servidor no Launcher, é possível se conectar diretamente ao server, que é explicado em detalhes <Link to={Paths.GuidesDirectConnectionPage}>neste guia</Link>. <br />
            É possível conferir os mods necessários para se conectar ao servidor  <Link to={"/"} onClick={redirectToMods} >aqui</Link>.
          </Typography.Title>
        </div>
      } />
    </div>
  );
}

function redirectToMods() {
  window.location.href = '/files/modsPreset.html';
}

export default WelcomePage;