import React from 'react';
import DonateItemPresenter from "./DonateItemPresenter";
import {Typography} from "antd";

function ExpansionVodnik() {
  return (
    <DonateItemPresenter
      itemName={"Vodnik"}
      images={[
        "expansionvodnik/camo",
        "expansionvodnik/camomedical",
      ]}
      trunk={"270 (10 x 27)"}
      seats={"8"}
      specialAbilities={"Veículo amfíbio: tem 2 motores e pode navegar na água"}
      insurance={"Sim, consulte a seção de seguros para mais informações"}
      price={<Typography.Title code>R$ 80,00</Typography.Title>}
    />
  );
}

export default ExpansionVodnik;