import ServerTierBase from "./ServerTierBase";

type Props = {
  showColor: boolean,
  showDescription: boolean,
  tailText: string,
}

function ServerTierColorGreen(props: Props) {
  return (
    ServerTierBase({
      color: ServerTierColorGreen.color,
      colorName: "Verde",
      description: ServerTierColorGreen.description,
      showColor: props.showColor,
      showDescription: props.showDescription,
      tailText: props.tailText
    })
  );
}

ServerTierColorGreen.description = "Local seguro e conta com proteção SafeZone"
ServerTierColorGreen.color = "#2ECC71"

ServerTierColorGreen.defaultProps = {
  showColor: true,
  showDescription: false,
  tailText: "",
}

export default ServerTierColorGreen;