import React from 'react';
import DonateItemPresenter from "./DonateItemPresenter";
import {Typography} from "antd";

function Truck01() {
  return (
    <DonateItemPresenter
      itemName={"Truck M3S"}
      images={[
        "truck_01_covered/green",
        "truck_01_covered/black",
        "truck_01_covered/blue",
        "truck_01_covered/grey",
        "truck_01_covered/lime",
        "truck_01_covered/orange",
        "truck_01_covered/red",
        "truck_01_covered/white",
        "truck_01_covered/wine",
        "truck_01_covered/yellow",
        "truck_01_covered/yellow_bright",
      ]}
      trunk={"600 (10 x 60)"}
      seats={"2"}
      specialSlots={
        <ul>
          <li>2 Estepes</li>
          <li>4 Barris: + 600 slots</li>
          <li>4 Caixas de madeira: +200 slots</li>
        </ul>
      }
      totalSlots={"Até 1.400"}
      insurance={"Sim, consulte a seção de seguros para mais informações"}
      price={<Typography.Title code>R$ 2,00</Typography.Title>}
    />
  );
}

export default Truck01;