import React from 'react';
import {ItemPresenter} from "../ItemPresenter";

function AnimalDoe() {
  return (
    <ItemPresenter imagePath={"/images/animals/doe.png"} bottomLabel={"Corça fêmea"}
      customImageWidth={" "}
      />
  );
}

export default AnimalDoe;