import React, {ReactComponentElement} from 'react';
import {Content} from "antd/es/layout/layout";
import {Layout, theme} from "antd";

type Props = {
  content: ReactComponentElement<any>
}

function ClearBody(props: Props) {
  const {
    token: { colorBgContainer},
  } = theme.useToken();


  return (
    <Layout style={{ padding: '0 96px 96px' }}>
      <Content
        style={{
          padding: 24,
          margin: 0,
          minHeight: 280,
          background: colorBgContainer,
        }}
      >
        {props.content}
      </Content>
    </Layout>

  );
}

export default ClearBody;