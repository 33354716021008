import React from 'react';
import {Breadcrumb, Divider, Image, Typography} from "antd";
import Title from "antd/es/typography/Title";
import ClearBody from "../../../components/ClearBody";
import {Paths} from "../../../Paths";
import {Link} from "react-router-dom";
import ItemQuantityDisplay from "../../../components/ItemQuantityDisplay";
import GIGuts from "../../../components/gameItems/GIGuts";
import {Table} from "antd/lib";
import Anchor from "antd/es/anchor/Anchor";
import GIPlantMaterial from "../../../components/gameItems/GIPlantMaterial";

function GuideBeginnerPage() {
  return (
    <ClearBody content={
      <div>
        <Breadcrumb style={{ margin: '16px 0' }}
          items={[
            {
              title: <Link to={Paths.GuidesPage}>Guias</Link>
            },
            {
              title: "Trabalhando o couro"
            }
          ]}
        />
        <Title>Trabalhando o couro</Title>

        <Typography.Title level={5} style={{marginLeft: 32}}>
          No jogo base do DayZ existe a mecânica de curtir peles em couros, mas aqui no servidor Zeds nós adicionamos mods pra deixar essa mecânica ainda mais profunda
          e realista. Transformar peles em couros dá um pouco de trabalho mas gera mais lucros do que simplesmente vender a pele para o caçador. Depois de curtir o couro ainda
          é possível costurar peças de couro o que permite o jogador obter ainda mais lucro em cima das peles de animais. <br />
          Abaixo é explicado
          como funciona esse processo: <br />
        </Typography.Title>

        <Anchor
          affix={false}
          items={[
            {
              key: 'HowTanning',
              href: '#HowTanning',
              title: 'Como curtir a pele em couro',
            },
            {
              key: 'Sewing',
              href: '#Sewing',
              title: 'Como costurar peças de couro',
            },
            {
              key: 'fertilizer',
              href: '#fertilizer',
              title: 'Como fabricar fertilizante',
            },
          ]}
        />

        <Typography.Title level={3} id={"HowTanning"}>
          <li>Como curtir a pele em couro</li>
        </Typography.Title>
        <Typography.Title level={5} style={{marginLeft: 32}}>
          Para o processo é necessário um barril (barril comum do jogo mesmo): <br />
          <Image src={"/images/leather/barrel.png"} width={128}/> <br />
          <Divider />
          Você precisará de fertilizante para o processo. O fertilizante pode ser fabricado (como fabricar fertilizante é explicado mais abaixo) ou pode ser comprado no contrabandista. <br />
          Para curtir é necessário que o barril possua dentro de si no mínimo uma quantidade específica de água, essa quantidade depende do tipo de pele e a quantidade de peles dentro do barril. <br />
          Como verificar quanto de água tem dentro do barril: <br />
          <Image src={"/images/leather/barrelWater.png"} width={128}/> <br />
          <Divider />
          Dado que o barril tenha a quantidade necessária de água, coloque dentro dele o fertilizante no local especial para curtir a pele e coloque dentro
          do barril a pele que quiser curtir. É possível colocar mais de uma pele de cada vez: <br />
          <Image src={"/images/leather/tanningBarrel.png"} width={256}/> <br />
          <Divider />
          Depois de colocar os itens nos locais correto, basta olhar para o barril que a opção de fechar a tampa e iniciar o processo aparecerá
          e então aperte F para iniciar o processo. <br />
          Após iniciar o processo, se olhar para barril aparecerá quanto tempo para que o processo termine: <br />
          <Image src={"/images/leather/tanningProcess.png"} width={256}/> <br />
          <Divider />
          Após o processo acabar, os couros estarão dentro do barril:<br />
          <Image src={"/images/leather/tannedLeather.png"} width={256}/> <br />
          <Divider />

          A quantidade de água, fertilizante, e couros referente ao processo são as seguintes: <br />
          <Table pagination={false} bordered
            columns={[
              {
               title: "Pele",
               dataIndex: "pelt",
               key: "pelt"
              },
              {
               title: "Couros por pele",
               dataIndex: "leather",
               key: "leather"
              },
              {
               title: "Tempo para curtir cada pele",
               dataIndex: "time",
               key: "time"
              },
              {
               title: "Consumo de fertilizante",
               dataIndex: "fertilizer",
               key: "fertilizer"
              },
              {
               title: "Consumo de água",
               dataIndex: "water",
               key: "water"
              }
            ]}
            dataSource={[
              {
               pelt: "Pele de vaca",
               leather: "12",
               time: "8 minutos e 30 segundos",
               fertilizer: "24% de um saco",
               water: "1,2 Litro",
               key: "cow"
              },
              {
               pelt: "Pele de urso",
               leather: "10",
               time: "10 minutos e 25 segundos",
               fertilizer: "20% de um saco",
               water: "8 Litros",
               key: "bear"
              },
              {
               pelt: "Pele de veado",
               leather: "8",
               time: "5 minutos",
               fertilizer: "16% de um saco",
               water: "2,250 Litros",
               key: "deer"
              },
              {
               pelt: "Pele de lobo",
               leather: "6",
               time: "2 minutos e 15 segundos",
               fertilizer: "12% de um saco",
               water: "4 Litros",
               key: "wolf"
              },
              {
                pelt: "Pele de javali",
                leather: "6",
                time: "2 minutos e 15 segundos",
                fertilizer: "12% de um saco",
                water: "2,2 Litros",
                key: "boar"
              },
              {
                pelt: "Pele de porco",
                leather: "6",
                time: "2 minutos e 30 segundos",
                fertilizer: "12% de um saco",
                water: "0,85 Litro",
                key: "pig"
              },
              {
                pelt: "Pele de cabra",
                leather: "4",
                time: "1 minuto e 30 segundos",
                fertilizer: "8% de um saco",
                water: "1,85 Litro",
                key: "goat"
              },
              {
                pelt: "Pele de ovelha",
                leather: "4",
                time: "1 minuto e 40 segundos",
                fertilizer: "8% de um saco",
                water: "3,8 Litros",
                key: "sheep"
              },
              {
                pelt: "Pele de coelho",
                leather: "1",
                time: "8 segundos",
                fertilizer: "2% de um saco",
                water: "0,450 Litros",
                key: "hare"
              }
            ]}
          />
        </Typography.Title>
        <Divider />

        <Typography.Title level={3} id={"Sewing"}>
          <li>Como costurar peças de couro</li>
        </Typography.Title>
        <Typography.Title level={5} style={{marginLeft: 32}}>
          Para fabricar peças de couro é bem simples, é só pegar as peças de couro curtido e colocar na mão, então arraste um kit de costura de couro
          nos couros que estão na mão. Ao fazer isso aparecerá a opção de quais peças podem ser costuradas:<br />
          <Image src={"/images/leather/sewing.png"} width={512}/> <br />
          <Divider />
          Importante mencionar que as opções que serão exibidas dependem da quantidade de couro disponível na pilha de couro selecionada,
          pois alguns itens necessitam de mais couros do que outros. As opções irão aparecer: <br />
          <Image src={"/images/leather/sewingOptions.png"} width={512}/> <br />
          <Divider />
          Após escolher a peça, segure o botão esquerdo do mouse enquanto a peça é costura e pronto! <br />
          <br />
          Abaixo é exibido a listagem de items disponível para costura: <br />
          <Table pagination={false} bordered
            columns={[
              {
                title: "Item",
                dataIndex: "item",
                key: "item"
              },
              {
                title: "Couros necessário",
                dataIndex: "leather",
                key: "leather"
              },
              {
                title: "Tamanho do item no inventário",
                dataIndex: "itemSize",
                key: "itemSize"
              },
              {
                title: "Utilização de kit de costura",
                dataIndex: "sewingKit",
                key: "sewingKit"
              },
              {
                title: "Bug quantidade de couro",
                dataIndex: "bug",
                key: "bug"
              }
            ]}
            dataSource={[
              {
                item: "Leather bottle / Garrafa de couro",
                leather: "1",
                itemSize: "2 x 4",
                sewingKit: "5% de um kit",
                bug: <Typography.Title level={5} style={{margin: "auto"}}>Não</Typography.Title>,
                key: "bottle"
              },
              {
                item: "Leather sheath / Bainha de couro",
                leather: "1",
                itemSize: "1 x 3",
                sewingKit: "5% de um kit",
                bug: <Typography.Title type={"danger"} level={5} style={{margin: "auto"}}>Sim</Typography.Title>,
                key: "sheath"
              },
              {
                item: "Leather gloves / Luvas de couro",
                leather: "1",
                itemSize: "2 x 2",
                sewingKit: "5% de um kit",
                bug: <Typography.Title type={"danger"} level={5} style={{margin: "auto"}}>Sim</Typography.Title>,
                key: "gloves"
              },
              {
                item: "Leather hat / Chapéu de couro",
                leather: "1",
                itemSize: "3 x 4",
                sewingKit: "5% de um kit",
                bug: <Typography.Title type={"danger"} level={5} style={{margin: "auto"}}>Sim</Typography.Title>,
                key: "hat"
              },
              {
                item: "Leather belt / Cinto de couro",
                leather: "1",
                itemSize: "1 x 3",
                sewingKit: "5% de um kit",
                bug: <Typography.Title type={"danger"} level={5} style={{margin: "auto"}}>Sim</Typography.Title>,
                key: "belt"
              },
              {
                item: "Leather shoes / Sapatos de couro",
                leather: "2",
                itemSize: "2 x 4",
                sewingKit: "5% de um kit",
                bug: <Typography.Title level={5} style={{margin: "auto"}}>Não</Typography.Title>,
                key: "shoes"
              },
              {
                item: "Leather pants / Calças de couro",
                leather: "2",
                itemSize: "4 x 4",
                sewingKit: "10% de um kit",
                bug: <Typography.Title level={5} style={{margin: "auto"}}>Não</Typography.Title>,
                key: "pants"
              },
              {
                item: "Leather vest / Colete de couro",
                leather: "4",
                itemSize: "4 x 3",
                sewingKit: "15% de um kit",
                bug: <Typography.Title level={5} style={{margin: "auto"}}>Não</Typography.Title>,
                key: "vest"
              },
              {
                item: "Leather jacket / Jaqueta de couro",
                leather: "5",
                itemSize: "4 x 4",
                sewingKit: "15% de um kit",
                bug: <Typography.Title level={5} style={{margin: "auto"}}>Não</Typography.Title>,
                key: "jacket"
              },
              {
                item: "Leather sack / Mochila de couro",
                leather: "5",
                itemSize: "5 x 6",
                sewingKit: "15% de um kit",
                bug: <Typography.Title level={5} style={{margin: "auto"}}>Não</Typography.Title>,
                key: "sack"
              },
            ]}
          />
          <Typography.Title type={"danger"} level={4} style={{margin: "auto"}}>
            Tome cuidado ao costurar os items que possuem Bug na quantidade de couro. Esses itens gastarão mais couro do que o que é exibido na listagem acima. Por exemplo, se ao costurar
            uma luva de couro a pilha de couro tiver mais do que apenas 1 couro, ao costurar a luva irá consumir vários couros da pilha além do esperado. Portanto para evitar problemas, deixe apenas a quantidade
            exibida acima na pilha de couro ao costurar os itens bugados. Uma outra opção é não costurar especificamente os items bugados, costurar apenas os itens corretamente configurados. Sobre o bug, infelizmente
            isso faz parte do mod e não há o que fazer, precisamos conviver com isso até que talvez um dia o dono do mod corrija esse problema.
          </Typography.Title>
        </Typography.Title>
        <Divider />

        <Typography.Title level={3} id={"fertilizer"}>
          <li>Como fabricar fertilizante</li>
        </Typography.Title>
        <Typography.Title level={5} style={{marginLeft: 32}}>
          Para fabricar fertilizantes é necessário utilizar o barril, da mesma forma como utilizado para curtir couro. <br />
          São necessários 3 itens para fabricação de fertilizantes: <br />
          <li><ItemQuantityDisplay item={<GIPlantMaterial />} quantityExact={{quantity: 1}} /></li> <br />
          <li><ItemQuantityDisplay item={<GIGuts />} quantityExact={{quantity: 1}} /></li> <br />
          <li> 25 Litros de água dentro do barril</li> <br />
          Basta colocar os itens nos espaços adequados no barril: <br />
          <Image src={"/images/leather/fertilizerItens.png"} width={300}/> <br />
          <Divider />
          <br />
          E então fechar a tampa do barril: <br />
          <Image src={"/images/leather/fertilizerAction.png"} width={300}/> <br />
          Após isso o fertilizante estará dentro do barril, só pegar e usar!
          <br />
        </Typography.Title>
        <Divider />
      </div>
    } />
  );
}

export default GuideBeginnerPage;