import React from 'react';
import ClearBody from "../../components/ClearBody";
import Title from "antd/es/typography/Title";
import {Space, Typography} from 'antd';
import {Link} from "react-router-dom";

import {useTranslation} from 'react-i18next';
import TranslatorLanguageChanger from "../../components/TranslatorLanguageChanger";

const { Text } = Typography;

function RulesPage() {
  const { t } = useTranslation();

  return (
    <ClearBody content={
      <div>
        <Space wrap>
          <Title>{t('rules.title')} </Title>
          <TranslatorLanguageChanger />
        </Space>
          <ol>
            <li>
              {t('rules.general.body')} <Text type="danger"> {t('rules.general.warning')} </Text>
              <ol>
                <li>{t('rules.general.item1.text')}</li>
                <li>{t('rules.general.item2.text')}</li>
                <li>{t('rules.general.item3.text')}</li>
                <li>{t('rules.general.item4.text')}</li>
                <li>{t('rules.general.item5.text')}</li>
                <li>{t('rules.general.item6.text')}</li>
              </ol>
            </li>
            <li>
              {t('rules.baseBuilding.text')}
              <ol>
                <li>{t('rules.baseBuilding.item1.text')}</li>
                <ol>
                  <li>{t('rules.baseBuilding.item1.item1.text')}</li>
                  <li>{t('rules.baseBuilding.item1.item2.text')}</li>
                  <li>{t('rules.baseBuilding.item1.item3.text')}</li>
                  <li>{t('rules.baseBuilding.item1.item4.text')}</li>
                  <li>{t('rules.baseBuilding.item1.item5.text')}</li>
                  <li><Text type="danger">{t('rules.baseBuilding.item1.item6.warning')}</Text> {t('rules.baseBuilding.item1.item6.doubt')} <Link to="https://discord.gg/BrmJcuRHJe">{t('rules.baseBuilding.item1.item6.askAdmin')}</Link>{t('rules.baseBuilding.item1.item6.endPhrase')}</li>
                </ol>
              </ol>
            </li>
            <li>
              {t('rules.events.text')}
              <ol>
                <li>{t('rules.events.item1.beginning')}<Text mark>{t('rules.events.item1.note')}</Text>{t('rules.events.item1.end')}</li>
                  <ol>
                    <li>{t('rules.events.item1.item1.text')}</li>
                  </ol>
              </ol>
            </li>
            <li>
              {t('rules.punishment.text')}
              <ol>
                <li>{t('rules.punishment.item1.text')}</li>
                <ol>
                  <li>{t('rules.punishment.item1.item1.text')}</li>
                  <ol>
                    <li>{t('rules.punishment.item1.item1.item1.text')}</li>
                    <li>{t('rules.punishment.item1.item1.item2.text')}</li>
                    <li>{t('rules.punishment.item1.item1.item3.text')}</li>
                    <li>{t('rules.punishment.item1.item1.item4.text')}</li>
                    <li>{t('rules.punishment.item1.item1.item5.text')}</li>
                  </ol>
                  <li>{t('rules.punishment.item1.item2.text')}</li>
                  <ol>
                    <li>{t('rules.punishment.item1.item2.item1.text')}</li>
                    <li>{t('rules.punishment.item1.item2.item2.text')}</li>
                  </ol>
                  <li>{t('rules.punishment.item1.item3.text')}</li>
                  <ol>
                    <li>{t('rules.punishment.item1.item3.item1.text')}</li>
                  </ol>
                </ol>
                <li>{t('rules.punishment.item2.text')}</li>
              </ol>
            </li>
            <li>{t('rules.bugs.text')}</li>
            <ol>
              <li>{t('rules.bugs.item1.text')}</li>
              <li>{t('rules.bugs.item2.text')}</li>
              <li>{t('rules.bugs.item3.text')}</li>
            </ol>
            <li>{t('rules.support.text')}</li>
            <ol>
              <li>{t('rules.support.item1.firstPart')} <Link to="https://discord.gg/BrmJcuRHJe">https://discord.gg/BrmJcuRHJe</Link>{t('rules.support.item1.end')}</li>
            </ol>
          </ol>
      </div>
    } />
  );
}

export default RulesPage;