import React from 'react';
import {ItemPresenter} from "../ItemPresenter";

const className = "chickenbreastmeat";

function GIChickenBreastMeat() {
  return (
    <ItemPresenter imagePath={`/images/gameItems/${className}.png`} tooltipText={className} />
  );
}

export default GIChickenBreastMeat;