import {MapInstance} from "./MapInstance";

export class MapInstanceUtils {
  public static getNameString(mapInstance: MapInstance): string | null {
    switch (mapInstance) {
      case MapInstance.CHERNARUS_PLUS:
        return "chernarusplus";
      default:
        return null;
    }
  }
}