import React, {useContext, useState} from 'react';
import type {MenuProps} from 'antd';
import {Menu} from "antd";
import {Link} from "react-router-dom";
import {Paths} from "../Paths";
import SteamHeader from "./steam/SteamHeader";
import {Col, Row} from "antd/lib";
import {BackendContext} from "../App";
import PlayerAuthInfo from "../backend/models/PlayerAuthInfo";


const headerStyle: React.CSSProperties = {
  textAlign: 'center',
  color: '#fff',
  height: 64,
  // paddingInline: 50,
  lineHeight: '64px',
  // backgroundColor: '#7dbcea',
};

const menuStyle: React.CSSProperties = {
  // backgroundColor:
  textDecoration: "underline",
  width: "100%"
}

function MasterHeader() {
  const backendContext = useContext(BackendContext);
  const [isAuthenticated, setIsAuthenticated] = useState(backendContext.hasPlayerAuth());
  const [isAdmin, setIsAdmin] = useState(backendContext.isAdmin());

  backendContext.wireNewPlayerAuthListener("master-header", (newPlayerAuth: PlayerAuthInfo | null) => {
    setIsAuthenticated(newPlayerAuth !== null);
    setIsAdmin(backendContext.isAdmin());
  });

  let items: MenuProps['items'] = [
    {
      label: <Link to={Paths.WelcomePage}>O servidor</Link>,
      key: 'server',

    },
    {
      label: <Link to={Paths.RulesPage}>Regras</Link>,
      key: 'rules'
    },
    {
      label: <Link to={Paths.GuidesPage}>Guias</Link>,
      key: 'guides'
    },
    {
      label: <Link to={Paths.ItemsDonatePage}>Itens donate</Link>,
      key: 'donates'
    },
    {
      label: "Área do jogador",
      key: 'player-area',
      style: {visibility: isAuthenticated ? "visible" : "hidden"},
      children: [
        {
          label: <Link to={Paths.PlayerAreaPage}>Acessar</Link>,
          key: 'player-area-home',
        },
        {
          label: <Link to={Paths.PlayerAreaRepairBagPage}>Reparar bolsa</Link>,
          key: 'player-area-repair-bag',
        }
      ]
    },
    {
      label: <Link to={Paths.AdminAreaPage}>Admin area</Link>,
      key: 'admin-area',
      style: {visibility: isAdmin ? "visible" : "hidden"},
    }
  ]

  return (
      <Row>
        <Col span={23}>
          <Menu
            items={items}
            style={menuStyle}
            theme="dark"
            mode="horizontal"
            // selectedKeys={['server']}
            // defaultSelectedKeys={['server']}
          />
        </Col>
        <Col span={1} style={{float: "right"}}>
          <SteamHeader />
        </Col>
      </Row>
  );
}

export default MasterHeader;