import React from 'react';
import {ItemPresenter} from "../ItemPresenter";

function AnimalCow() {
  return (
    <ItemPresenter imagePath={"/images/animals/bull.png"} bottomLabel={"Touro"}
      customImageWidth={" "}
      />
  );
}

export default AnimalCow;