import {Messages} from "./Messages";

export class StatusCodes {
  static UnauthorizedRequest = -7
  static InvalidSteamLogin = -1001
  static LoginSteamUnknownID = -1002
  static PlayerNotOnline = -2003
  static NothingBeingHeldOnHands = -2004
  static NoVipBagOnHands = -2005
  static VipBagNotDestroyed = -2006
  static NoValidRepairVoucherFound = -2008

  static parseCode(code: number): TranslationTexts {
    switch (code) {
      case StatusCodes.LoginSteamUnknownID:
        return new TranslationTexts(Messages.UnknownErrorTitle, Messages.UnknownErrorDescription);
      case StatusCodes.UnauthorizedRequest:
        return new TranslationTexts(Messages.UnauthorizedRequestTitle, Messages.UnauthorizedRequestDescription);
      case StatusCodes.PlayerNotOnline:
        return new TranslationTexts(Messages.PlayerNotOnlineTitle, Messages.PlayerNotOnlineDescription);
      case StatusCodes.NothingBeingHeldOnHands:
        return new TranslationTexts(Messages.NothingBeingHeldOnHandsTitle, Messages.NothingBeingHeldOnHandsDescription);
      case StatusCodes.NoVipBagOnHands:
        return new TranslationTexts(Messages.NoVipBagOnHandsTitle, Messages.NoVipBagOnHandsDescription);
      case StatusCodes.VipBagNotDestroyed:
        return new TranslationTexts(Messages.VipBagNotDestroyedTitle, Messages.VipBagNotDestroyedDescription);
      case StatusCodes.NoValidRepairVoucherFound:
        return new TranslationTexts(Messages.NoValidRepairVoucherFoundTitle, Messages.NoValidRepairVoucherFoundDescription);
      default:
        return new TranslationTexts(Messages.UnknownErrorTitle, Messages.UnknownErrorDescription);
    }
  }
}

export class TranslationTexts {
  title: string;
  description: string;

  constructor(title: string, description: string) {
    this.title = title;
    this.description = description;
  }
}