import React from 'react';
import {Dropdown, Image, MenuProps, Typography} from 'antd';
import {useTranslation} from "react-i18next";

const items: MenuProps['items'] = [
  {
    label: <Typography.Text style={{marginLeft: 4}}>Português</Typography.Text>,
    key: 'pt',
    icon: <Image width={24} src={"/images/flag/pt.png"} preview={false}/>,
  },
  {
    label: <Typography.Text style={{marginLeft: 4}}>Español</Typography.Text>,
    key: 'es',
    icon: <Image width={24} src={"/images/flag/es.png"} preview={false}/>,
  },
  {
    label: <Typography.Text style={{marginLeft: 4}}>English</Typography.Text>,
    key: 'en',
    icon: <Image width={24} src={"/images/flag/en.png"} preview={false}/>,
  },
];

function TranslatorLanguageChanger() {
  const { t, i18n  } = useTranslation();

  const clickedOnLanguage: MenuProps['onClick'] = (e) => {
    changedLanguage(e.key, i18n)
  };

  const menuProps = {
    items,
    onClick: clickedOnLanguage,
  };

  let language = i18n.resolvedLanguage
  if (language === undefined) {
    language = i18n.language;
    if (language.indexOf("-") >= 0) {
      language = language.substring(0, language.indexOf("-"))
    }
  }

  return (
    <Dropdown.Button
      menu={menuProps}
      icon={
        <Image width={24} src={`/images/flag/${language}.png`} preview={false}
          style={{marginBottom: 3}}
        />
      }
      style={{margin: 0, padding: 0, verticalAlign: "middle"}}
    >
      {t('languageSelector')}
    </Dropdown.Button>
  );
}

function changedLanguage(newLanguage: string, i18n: any) {
  i18n.changeLanguage(newLanguage)
}

export default TranslatorLanguageChanger;