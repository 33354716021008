import React from 'react';
import {ItemPresenter} from "../ItemPresenter";

function AnimalRam() {
  return (
    <ItemPresenter imagePath={"/images/animals/ram.png"} bottomLabel={"Carneiro"}
      customImageWidth={" "}
      />
  );
}

export default AnimalRam;