import React from 'react';
import DonateItemPresenter from "./DonateItemPresenter";
import {Typography} from "antd";

function ZedsBitelaBag() {
  return (
    <DonateItemPresenter
      itemName={"Mochila Bitela Zeds"}
      images={[
        "zeds_bag_carrier/black",
        "zeds_bag_carrier/alpine",
      ]}
      slots={"300 (10 x 30)"}
      specialSlots={
        <ul>
          <li>Arma</li>
          <li>MMG Medic Pouch</li>
          <li>Rádio</li>
          <li>Vareta luminosa</li>
          <li>Cantil</li>
        </ul>
      }
      repairing={<label>Reparável com fita adesiva e caso seja destruída, <a href='/player-area/repair-bag'> repare-a neste link </a>.</label>}
      insurance={"Ainda não é possível, então tome cuidado, caso perca a bag, perdeu."}
      price={<Typography.Title code>R$ 15,00</Typography.Title>}
    />
  );
}

export default ZedsBitelaBag;