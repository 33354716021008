import React from 'react';
import DonateItemPresenter from "./DonateItemPresenter";
import {Typography} from "antd";

function ExpansionMerlin() {
  return (
    <DonateItemPresenter
      itemName={"[Exp] Merlin"}
      images={[
        "expansionmerlin/green",
        "expansionmerlin/ion",
        "expansionmerlin/dahoma",
        "expansionmerlin/president",
        "expansionmerlin/vrana",
        "expansionmerlin/wave",
      ]}
      trunk={"800 (10 x 80)"}
      seats={"2"}
      insurance={"Sim, consulte a seção de seguros para mais informações"}
      price={<Typography.Title code>R$ 140,00</Typography.Title>}
    />
  );
}

export default ExpansionMerlin;