import React from 'react';
import {ItemPresenter} from "../ItemPresenter";

function AnimalBear() {
  return (
    <ItemPresenter imagePath={"/images/animals/bear.png"} bottomLabel={"Urso"}
      customImageWidth={" "}
      />
  );
}

export default AnimalBear;