import React from 'react';
import {Breadcrumb, Descriptions, Divider, Image, Layout, Space, theme, Tooltip, Typography} from "antd";
import Title from "antd/es/typography/Title";
import ClearBody from "../../../components/ClearBody";
import {Paths} from "../../../Paths";
import {Link} from "react-router-dom";
import Sider from "antd/es/layout/Sider";
import {Content} from "antd/es/layout/layout";
import ServerTierColorGreen from "../../../components/DangerServerTier/ServerTierColorGreen";
import {InfoCircleOutlined } from '@ant-design/icons';
import ServerTierColorBlue from "../../../components/DangerServerTier/ServerTierColorBlue";
import ServerTierColorOrange from "../../../components/DangerServerTier/ServerTierColorOrange";
import ServerTierColorRed from "../../../components/DangerServerTier/ServerTierColorRed";


function GuideBeginnerPage() {
  const {
    token: { colorBgLayout},
  } = theme.useToken();

  const siderStyle: React.CSSProperties = {
    backgroundColor: colorBgLayout,
  }

  return (
    <ClearBody content={
      <div>
        <Breadcrumb style={{ margin: '16px 0' }} items={[
          {
            title: <Link to={Paths.GuidesPage}>Guias</Link>
          },
          {
            title: "Traders"
          }
        ]} />
        <Title>Traders / Negociadores</Title>

        <Typography.Title level={5}>
          Atualmente temos vários Traders espalhados por Chernarus, comprando e vendendo diferentes itens. Abaixo temos uma breve descrição dos mesmos.
        </Typography.Title>
        <Divider />

        <Typography.Title level={3}>
          <li>Negociadores irmandade Green mountain</li>
        </Typography.Title>
        <Layout hasSider>
          <Sider width={"50%"} style={siderStyle}>
            <Image src={"/images/traders/greenMountain.png"} />
          </Sider>
          <Content style={{marginLeft: 16}}>
            <Typography.Title level={5}>
              Green mountain é uma zona de negócios que conta com o maior número de negociadores e possui a maior diversidade de itens sendo vendidos. Possui
              roupas básicas, equipamentos básicos, armas básicas, suprimentos básicos e alguns itens donates. Neste local há caixas eletrônicos. As comidas
              disponíveis são industrializadas e não há compra/venda de comidas naturais. É um dos possíveis locais de Spawn.
            </Typography.Title>

            <Descriptions layout={"horizontal"} bordered size={"small"} column={1}>
              <Descriptions.Item label="Como chegar?" span={1}>
                Qualquer forma, só se deslocar a pé ou de carro/helicóptero.
              </Descriptions.Item>
              <Descriptions.Item label="Precisa de cartão para acessar?">
                Não
              </Descriptions.Item>
              <Descriptions.Item label="Reputação mínima">
                0
              </Descriptions.Item>
              <Descriptions.Item label="Moédas aceitas">
                <Space >
                <Tooltip title="Dóllar">
                  <Image width={64} src={"/images/currencies/Dollar.png"} preview={false}/>
                </Tooltip>
                <Tooltip title="Moéda de ouro [Para itens Donate]">
                  <Image width={64} src={"/images/currencies/GoldCoin.png"} preview={false}/>
                </Tooltip>
                </Space>
              </Descriptions.Item>
              <Descriptions.Item label="Gás venenoso?">
                Nunca
              </Descriptions.Item>
              <Descriptions.Item label="Dificuldade acesso">
                <span style={{display: "flex"}}>
                  <ServerTierColorGreen />
                  <Tooltip title={ServerTierColorGreen.description} color={ServerTierColorGreen.color}>
                    <InfoCircleOutlined style={{marginLeft: 8}}/>
                  </Tooltip>
                </span>
              </Descriptions.Item>
              <Descriptions.Item label={
                <div>
                  <label style={{marginRight: 8}}>Área de conveniência</label>
                  <Image width={32} src={"/images/keycards/blue.png"}/>
                </div>}>
                Sim. Possui equipamentos para trabalhar minérios e Workbench para trabalho de Cannabis
              </Descriptions.Item>
            </Descriptions>
          </Content>
        </Layout>
        <Divider />

        <Typography.Title level={3}>
          <li>Vendinhas</li>
        </Typography.Title>
        <Layout hasSider>
          <Sider width={"50%"} style={siderStyle}>
            <Image  src={"/images/traders/vendinha.png"} />
          </Sider>
          <Content style={{marginLeft: 16}}>
            <Typography.Title level={5}>
              Vendinhas são pequenos quiosques que possuem algumas máquinas de auto-atendimento e um caixa-eletrônico. As comidas disponibilizadas nas vendinhas são naturais e orgânicas,
              não havendo a venda de industrializados. São pontos de respawn para quando o player morreu numa localização próxima de alguma vendinha específica.
            </Typography.Title>

            <Descriptions layout={"horizontal"} bordered size={"small"} column={1}>
              <Descriptions.Item label="Como chegar?" span={1}>
                Qualquer forma, só se deslocar a pé ou de carro/helicóptero.
              </Descriptions.Item>
              <Descriptions.Item label="Precisa de cartão para acessar?">
                Não
              </Descriptions.Item>
              <Descriptions.Item label="Reputação mínima">
                0
              </Descriptions.Item>
              <Descriptions.Item label="Moédas aceitas">
                <Space >
                  <Tooltip title="Dóllar">
                    <Image width={64} src={"/images/currencies/Dollar.png"} preview={false}/>
                  </Tooltip>
                </Space>
              </Descriptions.Item>
              <Descriptions.Item label="Gás venenoso?">
                Nunca
              </Descriptions.Item>
              <Descriptions.Item label="Dificuldade acesso">
                <span style={{display: "flex"}}>
                  <ServerTierColorBlue />
                  <Tooltip title={ServerTierColorBlue.description} color={ServerTierColorBlue.color}>
                    <InfoCircleOutlined style={{marginLeft: 8}}/>
                  </Tooltip>
                </span>
              </Descriptions.Item>
              <Descriptions.Item label={
                <div>
                  <label style={{marginRight: 8}}>Área de conveniência</label>
                </div>}>
                Não possui
              </Descriptions.Item>
            </Descriptions>
          </Content>
        </Layout>
        <Divider />

        <Typography.Title level={3}>
          <li>Veículos especiais</li>
        </Typography.Title>
        <Layout hasSider>
          <Sider width={"50%"} style={siderStyle}>
            <Image  src={"/images/traders/vehicleSpecials.png"} />
          </Sider>
          <Content style={{marginLeft: 16}}>
            <Typography.Title level={5}>
              Neste Trader você encontra todos os carros e pickups que são melhores que os carros básicos. Este ponto é um grupo de 2 negociadores e 1 máquina de auto-atendimento.
              1 dos negociadores vende veículos por dinheiro do jogo enquanto o outro vende os veículos por moédas de ouro [Donate]. A máquina de auto-atendimento vende as peças dos veículos
              que são vendidos neste local.
            </Typography.Title>
            <Descriptions layout={"horizontal"} bordered size={"small"} column={1}>
              <Descriptions.Item label="Como chegar?" span={1}>
                Qualquer forma, só se deslocar a pé ou de carro/helicóptero.
              </Descriptions.Item>
              <Descriptions.Item label="Precisa de cartão para acessar?">
                Não
              </Descriptions.Item>
              <Descriptions.Item label="Reputação mínima">
                1.000
              </Descriptions.Item>
              <Descriptions.Item label="Moédas aceitas">
                <Space >
                  <Tooltip title="Dóllar">
                    <Image width={64} src={"/images/currencies/Dollar.png"} preview={false}/>
                  </Tooltip>
                  <Tooltip title="Moéda de ouro [Para itens Donate]">
                    <Image width={64} src={"/images/currencies/GoldCoin.png"} preview={false}/>
                  </Tooltip>
                </Space>
              </Descriptions.Item>
              <Descriptions.Item label="Gás venenoso?">
                Nunca
              </Descriptions.Item>
              <Descriptions.Item label="Dificuldade acesso">
                <span style={{display: "flex"}}>
                  <ServerTierColorBlue />
                  <Tooltip title={ServerTierColorBlue.description} color={ServerTierColorBlue.color}>
                    <InfoCircleOutlined style={{marginLeft: 8}}/>
                  </Tooltip>
                </span>
              </Descriptions.Item>
              <Descriptions.Item label={
                <div>
                  <label style={{marginRight: 8}}>Área de conveniência</label>
                  <Image width={32} src={"/images/keycards/blue.png"}/>
                </div>}>
                Ainda não - está sendo implementado
              </Descriptions.Item>
            </Descriptions>
          </Content>
        </Layout>
        <Divider />

        <Typography.Title level={3}>
          <li>Companhia Dumont</li>
        </Typography.Title>
        <Layout hasSider>
          <Sider width={"50%"} style={siderStyle}>
            <Image  src={"/images/traders/dumont.png"} />
          </Sider>
          <Content style={{marginLeft: 16}}>
            <Typography.Title level={5}>
              A Companhia Dumont é uma empresa que se especializou no setor aeronático em Chernarus. Nela é possível comprar e vender
              aeronaves bem como peças e acessórios para aeronaves.
            </Typography.Title>
            <Descriptions layout={"horizontal"} bordered size={"small"} column={1}>
              <Descriptions.Item label="Como chegar?" span={1}>
                Qualquer forma, só se deslocar a pé ou de carro/helicóptero.
              </Descriptions.Item>
              <Descriptions.Item label="Precisa de cartão para acessar?">
                Não
              </Descriptions.Item>
              <Descriptions.Item label="Reputação mínima">
                1.000
              </Descriptions.Item>
              <Descriptions.Item label="Moédas aceitas">
                <Space >
                  <Tooltip title="Dóllar">
                    <Image width={64} src={"/images/currencies/Dollar.png"} preview={false}/>
                  </Tooltip>
                  <Tooltip title="Moéda de ouro [Para itens Donate]">
                    <Image width={64} src={"/images/currencies/GoldCoin.png"} preview={false}/>
                  </Tooltip>
                </Space>
              </Descriptions.Item>
              <Descriptions.Item label="Gás venenoso?">
                As vezes
              </Descriptions.Item>
              <Descriptions.Item label="Dificuldade acesso">
                <span style={{display: "flex"}}>
                  <ServerTierColorBlue />
                  <Tooltip title={ServerTierColorBlue.description} color={ServerTierColorBlue.color}>
                    <InfoCircleOutlined style={{marginLeft: 8}}/>
                  </Tooltip>
                </span>
              </Descriptions.Item>
              <Descriptions.Item label={
                <div>
                  <label style={{marginRight: 8}}>Área de conveniência</label>
                  <Image width={32} src={"/images/keycards/blue.png"}/>
                </div>}>
                Ainda não - está sendo implementado
              </Descriptions.Item>
            </Descriptions>
          </Content>
        </Layout>
        <Divider />

        <Typography.Title level={3}>
          <li>Contrabandista</li>
        </Typography.Title>
        <Layout hasSider>
          <Sider width={"50%"} style={siderStyle}>
            <Image  src={"/images/traders/contrabandista.png"} />
          </Sider>
          <Content style={{marginLeft: 16}}>
            <Typography.Title level={5}>
              O contrabandista é um Trader que possui alguns itens exclusivos que não são encontrados normalmente. Além disso por ser um contrabandista,
              alguns produtos são mais baratos do que em Traders comuns, vale a pena dar uma olhada.
            </Typography.Title>
            <Descriptions layout={"horizontal"} bordered size={"small"} column={1}>
              <Descriptions.Item label="Como chegar?" span={1}>
                Qualquer forma, só se deslocar a pé ou de carro/helicóptero.
              </Descriptions.Item>
              <Descriptions.Item label="Precisa de cartão para acessar?">
                Não
              </Descriptions.Item>
              <Descriptions.Item label="Reputação mínima">
                1.000
              </Descriptions.Item>
              <Descriptions.Item label="Moédas aceitas">
                <Space >
                  <Tooltip title="Dóllar">
                    <Image width={64} src={"/images/currencies/Dollar.png"} preview={false}/>
                  </Tooltip>
                </Space>
              </Descriptions.Item>
              <Descriptions.Item label="Gás venenoso?">
                As vezes
              </Descriptions.Item>
              <Descriptions.Item label="Dificuldade acesso">
                <span style={{display: "flex"}}>
                  <ServerTierColorOrange />
                  <Tooltip title={ServerTierColorOrange.description} color={ServerTierColorOrange.color}>
                    <InfoCircleOutlined style={{marginLeft: 8}}/>
                  </Tooltip>
                </span>
              </Descriptions.Item>
              <Descriptions.Item label={
                <div>
                  <label style={{marginRight: 8}}>Área de conveniência</label>
                  <Image width={32} src={"/images/keycards/blue.png"}/>
                </div>}>
                Sim. Possui ATMs e betoneira pra transformar cimento em tijolos do mod BBP [única betoneira no server]
              </Descriptions.Item>
            </Descriptions>
          </Content>
        </Layout>
        <Divider />

        <Typography.Title level={3}>
          <li>Caçador</li>
        </Typography.Title>
        <Layout hasSider>
          <Sider width={"50%"} style={siderStyle}>
            <Image  src={"/images/traders/hunter.png"} />
          </Sider>
          <Content style={{marginLeft: 16}}>
            <Typography.Title level={5}>
              O Caçador é um grupo de Traders que se dedicam à caça e o ao trabalho do couro. Neste local você poderá comprar ou vender
              tudo relacionado à caça de animais e seu abate (cabeças, peles, carnes, gorduras, entranhas & etc), bem como itens derivados destes, como por exemplo o couro e artigos
              feitos com couro.
            </Typography.Title>
            <Descriptions layout={"horizontal"} bordered size={"small"} column={1}>
              <Descriptions.Item label="Como chegar?" span={1}>
                Qualquer forma, só se deslocar a pé ou de carro/helicóptero.
              </Descriptions.Item>
              <Descriptions.Item label="Precisa de cartão para acessar?">
                Não
              </Descriptions.Item>
              <Descriptions.Item label="Reputação mínima">
                500
              </Descriptions.Item>
              <Descriptions.Item label="Moédas aceitas">
                <Space >
                  <Tooltip title="Dóllar">
                    <Image width={64} src={"/images/currencies/Dollar.png"} preview={false}/>
                  </Tooltip>
                </Space>
              </Descriptions.Item>
              <Descriptions.Item label="Gás venenoso?">
                Nunca
              </Descriptions.Item>
              <Descriptions.Item label="Dificuldade acesso">
                <span style={{display: "flex"}}>
                  <ServerTierColorOrange />
                  <Tooltip title={ServerTierColorOrange.description} color={ServerTierColorOrange.color}>
                    <InfoCircleOutlined style={{marginLeft: 8}}/>
                  </Tooltip>
                </span>
              </Descriptions.Item>
              <Descriptions.Item label={
                <div>
                  <label style={{marginRight: 8}}>Área de conveniência</label>
                  <Image width={32} src={"/images/keycards/blue.png"}/>
                </div>}>
                Ainda não - está sendo implementado
              </Descriptions.Item>
            </Descriptions>
          </Content>
        </Layout>
        <Divider />

        <Typography.Title level={3}>
          <li>Seal Symor Tyler</li>
        </Typography.Title>
        <Layout hasSider>
          <Sider width={"50%"} style={siderStyle}>
            <Image  src={"/images/traders/sealSymor.png"} />
          </Sider>
          <Content style={{marginLeft: 16}}>
            <Typography.Title level={5}>
              O Seal Symor Tyler é um combatente experiente e que tem sobrevivido ao apocalipse utilizando suas técnicas aprendidas no treinamento do Seals
              e aprimoradas no combate em Chernarus. Symor é especialista nas armas e attachmets do mod Morty e compra e vende todas as armas relacionadas ao mod Morty que sejam de calibres vanilla.
            </Typography.Title>
            <Descriptions layout={"horizontal"} bordered size={"small"} column={1}>
              <Descriptions.Item label="Como chegar?" span={1}>
                Via marítima (barcos): há um deck próximo, facilitando bastante o acesso. <br/>
                Via terrestre (a pé ou veículos): pegar a ponte que liga o continente à ilha de Skalist e atravessar a pé pois carros não conseguem passar da ponte. <br />
                Via aérea (helicópteros): Não é possível. Não é permitido pousar na ilha de Skalist (proibido sobrevoar a ilha e Bots equipados com Alligators irão garantir isso). <br />
              </Descriptions.Item>
              <Descriptions.Item label="Precisa de cartão para acessar?">
                Não
              </Descriptions.Item>
              <Descriptions.Item label="Reputação mínima">
                1.000
              </Descriptions.Item>
              <Descriptions.Item label="Moédas aceitas">
                <Space >
                  <Tooltip title="Dóllar">
                    <Image width={64} src={"/images/currencies/Dollar.png"} preview={false}/>
                  </Tooltip>
                </Space>
              </Descriptions.Item>
              <Descriptions.Item label="Gás venenoso?">
                Nunca
              </Descriptions.Item>
              <Descriptions.Item label="Dificuldade acesso">
                <span style={{display: "flex"}}>
                  <ServerTierColorOrange />
                  <Tooltip title={ServerTierColorOrange.description} color={ServerTierColorOrange.color}>
                    <InfoCircleOutlined style={{marginLeft: 8}}/>
                  </Tooltip>
                </span>
              </Descriptions.Item>
              <Descriptions.Item label={
                <div>
                  <label style={{marginRight: 8}}>Área de conveniência</label>
                  <Image width={32} src={"/images/keycards/blue.png"}/>
                </div>}>
                Sim. Possui ATMs
              </Descriptions.Item>
            </Descriptions>
          </Content>
        </Layout>
        <Divider />

        <Typography.Title level={3}>
          <li>Seal Bisna Fudry</li>
        </Typography.Title>
        <Layout hasSider>
          <Sider width={"50%"} style={siderStyle}>
            <Image  src={"/images/traders/sealBisna.png"} />
          </Sider>
          <Content style={{marginLeft: 16}}>
            <Typography.Title level={5}>
              O Seal Bisna Fudry é um combatente experiente e que tem sobrevivido ao apocalipse utilizando suas técnicas aprendidas no treinamento do Seals
              e aprimoradas no combate em Chernarus. Bisna é especialista nas armas e attachmets do mod Snafu e compra e vende todas as armas relacionadas ao mod Snafu que sejam de calibres vanilla.
            </Typography.Title>
            <Descriptions layout={"horizontal"} bordered size={"small"} column={1}>
              <Descriptions.Item label="Como chegar?" span={1}>
                Qualquer forma, só se deslocar a pé ou de carro/helicóptero.
              </Descriptions.Item>
              <Descriptions.Item label="Precisa de cartão para acessar?">
                Não
              </Descriptions.Item>
              <Descriptions.Item label="Reputação mínima">
                1.000
              </Descriptions.Item>
              <Descriptions.Item label="Moédas aceitas">
                <Space >
                  <Tooltip title="Dóllar">
                    <Image width={64} src={"/images/currencies/Dollar.png"} preview={false}/>
                  </Tooltip>
                </Space>
              </Descriptions.Item>
              <Descriptions.Item label="Gás venenoso?">
                Nunca
              </Descriptions.Item>
              <Descriptions.Item label="Dificuldade acesso">
                <span style={{display: "flex"}}>
                  <ServerTierColorOrange />
                  <Tooltip title={ServerTierColorOrange.description} color={ServerTierColorOrange.color}>
                    <InfoCircleOutlined style={{marginLeft: 8}}/>
                  </Tooltip>
                </span>
              </Descriptions.Item>
              <Descriptions.Item label={
                <div>
                  <label style={{marginRight: 8}}>Área de conveniência</label>
                  <Image width={32} src={"/images/keycards/blue.png"}/>
                </div>}>
                Sim. Possui ATMs
              </Descriptions.Item>
            </Descriptions>
          </Content>
        </Layout>
        <Divider />

        <Typography.Title level={3}>
          <li>Minerador</li>
        </Typography.Title>
        <Layout hasSider>
          <Sider width={"50%"} style={siderStyle}>
            <Image  src={"/images/traders/minerador.png"} />
          </Sider>
          <Content style={{marginLeft: 16}}>
            <Typography.Title level={5}>
              O Minerador é um Trader que compra e vende minérios. Nele é possível comprar e vender minérios, pepitas e barras dos metais preciosos de Chernarus! Ele vai
              te ajudar a ficar rico!
            </Typography.Title>
            <Descriptions layout={"horizontal"} bordered size={"small"} column={1}>
              <Descriptions.Item label="Como chegar?" span={1}>
                Qualquer forma, só se deslocar a pé ou de carro/helicóptero.
              </Descriptions.Item>
              <Descriptions.Item label="Precisa de cartão para acessar?">
                Não
              </Descriptions.Item>
              <Descriptions.Item label="Reputação mínima">
                500
              </Descriptions.Item>
              <Descriptions.Item label="Moédas aceitas">
                <Space >
                  <Tooltip title="Dóllar">
                    <Image width={64} src={"/images/currencies/Dollar.png"} preview={false}/>
                  </Tooltip>
                </Space>
              </Descriptions.Item>
              <Descriptions.Item label="Gás venenoso?">
                Nunca
              </Descriptions.Item>
              <Descriptions.Item label="Dificuldade acesso">
                <span style={{display: "flex"}}>
                  <ServerTierColorOrange />
                  <Tooltip title={ServerTierColorOrange.description} color={ServerTierColorOrange.color}>
                    <InfoCircleOutlined style={{marginLeft: 8}}/>
                  </Tooltip>
                </span>
              </Descriptions.Item>
              <Descriptions.Item label={
                <div>
                  <label style={{marginRight: 8}}>Área de conveniência</label>
                  <Image width={32} src={"/images/keycards/blue.png"}/>
                </div>}>
                Ainda não - está sendo implementado
              </Descriptions.Item>
            </Descriptions>
          </Content>
        </Layout>
        <Divider />

        <Typography.Title level={3}>
          <li>Black</li>
        </Typography.Title>
        <Layout hasSider>
          <Sider width={"50%"} style={siderStyle}>
            <Image src={"/images/traders/black.png"} />
          </Sider>
          <Content style={{marginLeft: 16}}>
            <Typography.Title level={5}>
              O Black market é um trader que tem diversos Seals e são especializados em venda de armamentos em Chernarus. O Seal Chuck só
              aceita fragmentos de arma como moéda de troca por armas de calibres de mods. Há também o seal Mac que troca dinheiro e reputação
              por fragmentos de arma. Adicionalmente há seals que vendem armas de mod e vanilla de calibres vanilla bem como venda de
              munições calibra vanilla e de mod. Também só na black que há a venda de armas e munições explosivas.
            </Typography.Title>
            <Descriptions layout={"horizontal"} bordered size={"small"} column={1}>
              <Descriptions.Item label="Como chegar?" span={1}>
                Qualquer forma, só se deslocar a pé ou de carro/helicóptero.
              </Descriptions.Item>
              <Descriptions.Item label="Precisa de cartão para acessar?">
                Sim. <Image width={32} src={"/images/keycards/violet.png"}/>
              </Descriptions.Item>
              <Descriptions.Item label="Reputação mínima">
                1.000
              </Descriptions.Item>
              <Descriptions.Item label="Moédas aceitas">
                <Space >
                  <Tooltip title="Dóllar">
                    <Image width={64} src={"/images/currencies/Dollar.png"} preview={false}/>
                  </Tooltip>
                  <Tooltip title="Fragmento de arma">
                    <Image width={32} src={"/images/currencies/Weapon-fragments.png"} preview={false}/>
                  </Tooltip>
                </Space>
              </Descriptions.Item>
              <Descriptions.Item label="Gás venenoso?">
                Nunca
              </Descriptions.Item>
              <Descriptions.Item label="Dificuldade acesso">
                <span style={{display: "flex"}}>
                  <ServerTierColorRed />
                  <Tooltip title={ServerTierColorRed.description} color={ServerTierColorRed.color}>
                    <InfoCircleOutlined style={{marginLeft: 8}}/>
                  </Tooltip>
                </span>
              </Descriptions.Item>
              <Descriptions.Item label={
                <div>
                  <label style={{marginRight: 8}}>Área de conveniência</label>
                  <Image width={32} src={"/images/keycards/blue.png"}/>
                </div>}>
                Sim. Possui ATMs
              </Descriptions.Item>
            </Descriptions>
          </Content>
        </Layout>
        <Divider />

        <Typography.Title level={3}>
          <li>Traficante</li>
        </Typography.Title>
        <Layout hasSider>
          <Sider width={"50%"} style={siderStyle}>
            <Image  src={"/images/traders/traficante.png"} />
          </Sider>
          <Content style={{marginLeft: 16}}>
            <Typography.Title level={5}>
              O traficante é um Trader que compra e vende drogas. Nele é possível comprar e vender drogas e itens relacionados ao cultivo de drogas. Os guardas
              (vestidos de verde) não oferecem risco ao player (não confundir com os bots de vermelho que sim oferecem riscos).
            </Typography.Title>
            <Descriptions layout={"horizontal"} bordered size={"small"} column={1}>
              <Descriptions.Item label="Como chegar?" span={1}>
                Qualquer forma, só se deslocar a pé ou de carro/helicóptero.
              </Descriptions.Item>
              <Descriptions.Item label="Precisa de cartão para acessar?">
                Não
              </Descriptions.Item>
              <Descriptions.Item label="Reputação mínima">
                2.000
              </Descriptions.Item>
              <Descriptions.Item label="Moédas aceitas">
                <Space >
                  <Tooltip title="Dóllar">
                    <Image width={64} src={"/images/currencies/Dollar.png"} preview={false}/>
                  </Tooltip>
                  <Tooltip title="Cigarro">
                    <Image width={64} src={"/images/currencies/Cigarete.png"} preview={false}/>
                  </Tooltip>
                  <Tooltip title="Folhas de coca">
                    <Image width={32} src={"/images/currencies/Coca.png"} preview={false}/>
                  </Tooltip>
                </Space>
              </Descriptions.Item>
              <Descriptions.Item label="Gás venenoso?">
                Nunca
              </Descriptions.Item>
              <Descriptions.Item label="Dificuldade acesso">
                <span style={{display: "flex"}}>
                  <ServerTierColorRed />
                  <Tooltip title={ServerTierColorRed.description} color={ServerTierColorRed.color}>
                    <InfoCircleOutlined style={{marginLeft: 8}}/>
                  </Tooltip>
                </span>
              </Descriptions.Item>
              <Descriptions.Item label={
                <div>
                  <label style={{marginRight: 8}}>Área de conveniência</label>
                  <Image width={32} src={"/images/keycards/blue.png"}/>
                </div>}>
                Ainda não - está sendo implementado
              </Descriptions.Item>
            </Descriptions>
          </Content>
        </Layout>
        <Divider />

      </div>
    } />
  );
}

export default GuideBeginnerPage;