import './CleanTooltip.css';
import {Tooltip} from "react-leaflet";

type Props = {
  content: string
}

function CleanTooltip(props: Props) {
  return (
      <Tooltip
        permanent={true}
        content={props.content}
        offset={[0, 0]}
      />
  );
}

export default CleanTooltip;