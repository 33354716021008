import React from 'react';
import {Breadcrumb, Divider, Image, Typography} from "antd";
import Title from "antd/es/typography/Title";
import ClearBody from "../../../components/ClearBody";
import {Paths} from "../../../Paths";
import {Link} from "react-router-dom";
import ServerTierColorGreen from "../../../components/DangerServerTier/ServerTierColorGreen";
import ServerTierColorBlue from "../../../components/DangerServerTier/ServerTierColorBlue";
import ServerTierColorOrange from "../../../components/DangerServerTier/ServerTierColorOrange";
import ServerTierColorRed from "../../../components/DangerServerTier/ServerTierColorRed";

function GuideBeginnerPage() {
  return (
    <ClearBody content={
      <div>
        <Breadcrumb style={{ margin: '16px 0' }}
          items={[
            {
              title: <Link to={Paths.GuidesPage}>Guias</Link>
            },
            {
              title: "Mapa e marcadores"
            }
          ]}
        />
        <Title>Mapa e marcadores</Title>

        <Typography.Title level={5} style={{width: 1000}}>
          Abaixo temos o mapa de Chernarus do nosso servidor; <br />
          Importante notar que as cores dos marcadores no mapa significa, o risco de acessar tal local:
          <ul>
            <li>
              <ServerTierColorGreen showDescription={true} tailText={"."}/>
            </li>
            <li>
              <ServerTierColorBlue showDescription={true} tailText={"."}/>
            </li>
            <li>
              <ServerTierColorOrange showDescription={true} tailText={"."}/>
            </li>
            <li>
              <ServerTierColorRed showDescription={true} tailText={"."}/>
            </li>
          </ul>
        </Typography.Title>
        <Divider />
        <Image src={"/images/map.png"}  />
      </div>
    } />
  );
}

export default GuideBeginnerPage;