import React, {useContext, useState} from 'react';
import {Dropdown, Image, MenuProps} from "antd";
import {LinkOutlined, LogoutOutlined} from '@ant-design/icons';
import {BackendContext} from "../../App";
import SteamPlayerPublicInfoDTO from "../../backend/models/SteamPlayerPublicInfoDTO";
import {useNavigate} from "react-router-dom";
import {Paths} from "../../Paths";


const steamLogoDivStyle: React.CSSProperties = {
  marginTop: "11px",
  background: "#e7e7e7",
  textAlign: "center",
  height: "42px",
  width: "42px",
  borderRadius: "4px",
};

const steamLogoImageStyle: React.CSSProperties = {
  marginBottom: "25px",
}

function SteamAuthenticatedHeader() {
  const backendContext = useContext(BackendContext);
  const navigate = useNavigate();

  const [steamPlayer, setSteamPlayer] = useState<SteamPlayerPublicInfoDTO | null>(null)

  if (steamPlayer === null) {
    backendContext.retrieveMySteamInfo().then(response => {
      setSteamPlayer(response.playerInfo)

    }).catch(reason => {
      console.error(`Error fetching my steam info: ${JSON.stringify(reason)}`)
    });
  }

  const steam = () => {
    if (steamPlayer !== null) {
      window.open(steamPlayer.profileurl ?? "", '_blank');
    }
  }
  const logout = () => {
    backendContext.clearPlayerAuth()
    navigate(Paths.WelcomePage);
  }

  const items: MenuProps['items'] = [
    {
      label: steamPlayer?.personaname,
      icon: <LinkOutlined />,
      key: 'steam',
      onClick: steam,
    },
    {
      label: "Logout",
      icon: <LogoutOutlined />,
      key: 'logout',
      onClick: logout,
    }
  ]

  return (
      <div style={steamLogoDivStyle}>
        <Dropdown menu={{ items }} placement="bottomLeft">
          <div hidden={steamPlayer?.avatarfull === null || steamPlayer?.avatarfull === undefined}>
            <Image src={steamPlayer?.avatarfull ?? ""}  height={30} width={30} preview={false} style={steamLogoImageStyle} />
          </div>
        </Dropdown>
      </div>
  );
}

export default SteamAuthenticatedHeader;