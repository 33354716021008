import React from 'react';
import DonateItemPresenter from "./DonateItemPresenter";
import {Typography} from "antd";

function ExpansionLandRover() {
  return (
    <DonateItemPresenter
      itemName={"Land rover"}
      images={[
        "expansion_landrover/green",
        "expansion_landrover/blue",
        "expansion_landrover/grey",
        "expansion_landrover/orange",
        "expansion_landrover/red",
        "expansion_landrover/yellow",
      ]}
      trunk={"500 (10 x 50)"}
      seats={"4"}
      specialSlots={
        <ul>
          <li>Estepe</li>
          <li>Galão de gasolina</li>
          <li>Baú do mar: +100 slots</li>
          <li>3 Caixas de madeira: +150 slots</li>
        </ul>
      }
      totalSlots={"Até 750"}
      insurance={"Sim, consulte a seção de seguros para mais informações"}
      price={<Typography.Title code>R$ 50,00</Typography.Title>}
    />
  );
}

export default ExpansionLandRover;