import React from 'react';
import {Breadcrumb, Divider, Image, theme, Typography} from "antd";
import Title from "antd/es/typography/Title";
import ClearBody from "../../../components/ClearBody";
import {Paths} from "../../../Paths";
import {Link} from "react-router-dom";
import {Table} from "antd/lib";


function GuideBeginnerPage() {
  return (
    <ClearBody content={
      <div>
        <Breadcrumb style={{ margin: '16px 0' }} items={[
          {
            title: <Link to={Paths.GuidesPage}>Guias</Link>
          },
          {
            title: "Trabalhando metais preciosos"
          }
        ]} />
        <Title>Trabalhando metais preciosos</Title>

        <Typography.Title level={5}>
          Metais preciosos são itens valiosos e que precisam ser trabalhados para virar dinheiro! Neste tutorial você irá aprender a como
          trabalhá-los. <br />
          As ferramentas necessárias para fazer esses processos podem ser compradas no <Link to={Paths.GuidesTradersPage}>Contrabandista</Link>.

        </Typography.Title>
        <Divider />

        <Typography.Title level={3}>
          <li>Minérios existentes</li>
        </Typography.Title>
        <Typography.Title level={5}>
          <Table pagination={false}
                 columns={[
                   {
                     title: "Material",
                     dataIndex: "material"
                   },
                   {
                     title: "Ore / minério",
                     dataIndex: "ore"
                   },
                   {
                     title: "Nugget / pepita",
                     dataIndex: "nugget"
                   },
                   {
                     title: "bar / barra",
                     dataIndex: "bar"
                   }
                 ]}
                 dataSource={[
                   {
                     material: "Copper / Cobre",
                     ore: <Image src={"/images/metals/copper-ore.png"} width={"50px"} />,
                     nugget: <Image src={"/images/metals/copper-nugget.png"} width={"50px"} />,
                     bar: <Image src={"/images/metals/copper-bar.png"} width={"58px"} />
                   },
                   {
                     material: "Silver / Prata",
                     ore: <Image src={"/images/metals/silver-ore.png"} width={"50px"} />,
                     nugget: <Image src={"/images/metals/silver-nugget.png"} width={"50px"} />,
                     bar: <Image src={"/images/metals/silver-bar.png"} width={"52px"} style={{marginLeft: 5}}/>
                   },
                   {
                     material: "Gold / Ouro",
                     ore: <Image src={"/images/metals/gold-ore.png"} width={"50px"} />,
                     nugget: <Image src={"/images/metals/gold-nugget.png"} width={"50px"} />,
                     bar: <Image src={"/images/metals/gold-bar.png"} width={"55px"}/>
                   },
                   {
                     material: "Uranium / Urânio",
                     ore: <Image src={"/images/metals/uranium-ore.png"} width={"50px"} />,
                     nugget: <Image src={"/images/metals/uranium-nugget.png"} width={"50px"} />,
                     bar: <Image src={"/images/metals/uranium-bar.png"} width={"58px"} style={{marginLeft: 1}}/>
                   }
                 ]} />
        </Typography.Title>
        <Divider />

        <Typography.Title level={3}>
          <li>Ferramentas utilizadas para trabalhar os minérios</li>
        </Typography.Title>
        <Typography.Title level={5}>
          <Image src={"/images/metals/metals-processing-tools.png"} width={"50%"}/>
        </Typography.Title>
        <Divider />
        <br />

        <Typography.Title level={3}>
          <li>
            Como transformar <label style={{textDecoration: "underline"}}>ore / minério</label> em <label style={{textDecoration: "underline"}}>nugget / pepita</label>:
          </li>
        </Typography.Title>
        <Typography.Title level={5}>
          Essa transformação é feita ao colocar o minério específico dentro da fornalha: <br />
          <Image src={"/images/metals/furnace-metals.png"} width={"40%"}/>
          <Typography.Text type={"danger"} style={{marginLeft: 16}}>Importante notar que os 4 slots de minério devem estar preenchidos para poder ligar a fornalha</Typography.Text>
          <br />
          <br />
          Ao colocar os minérios necessários, ao olhar para a fornalha aparecerá a opção de ligar a mesma para derreter os metais: <br />
          <Image src={"/images/metals/furnace-start.png"} width={"40%"}/><br />
          <br />
          Com o tempo os metais serão derretidos, de forma que após serem derretidos os nuggets aparecerão no inventário da fornalha: <br />
          <Image src={"/images/metals/furnace-smelted-ores.png"} width={"40%"}/><br />
        </Typography.Title>
        <Divider />
        <br />

        <Typography.Title level={3}>
          <li>
            Como transformar <label style={{textDecoration: "underline"}}>nuggets / pepitas</label> em <label style={{textDecoration: "underline"}}>bar / barra</label>:
          </li>
        </Typography.Title>
        <Typography.Title level={5}>
          Essa transformação é feita ao 10 pepitas na mão:<br />
          <Image src={"/images/metals/anvil-hands.png"} width={"40%"}/>
          <Typography.Text type={"danger"} style={{marginLeft: 16}}>tem que ser 10 pepitas para fabricar a barra</Typography.Text>
          <br />
          <br />
          Com as pepitas em mãos, olhe para o Anvil / bigorna: <br />
          <Image src={"/images/metals/anvil-start.png"} width={"40%"}/>
          <br />
          <br />
          Após fabricar a bar / barra, ela estará no chão.
        </Typography.Title>
        <Divider />


      </div>
    } />
  );
}

export default GuideBeginnerPage;