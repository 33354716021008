import React from 'react';
import DonateItemPresenter from "./DonateItemPresenter";
import {Typography} from "antd";

function ExpansionBus() {
  return (
    <DonateItemPresenter
      itemName={"Bus"}
      images={[
        "expansionbus/blue",
        "expansionbus/red",
        "expansionbus/weeb",
      ]}
      trunk={"500 (10 x 50)"}
      seats={"22"}
      insurance={"Sim, consulte a seção de seguros para mais informações"}
      price={<Typography.Title code>R$ 4,00</Typography.Title>}
    />
  );
}

export default ExpansionBus;