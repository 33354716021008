import ServerTierBase from "./ServerTierBase";

type Props = {
  showColor: boolean,
  showDescription: boolean,
  tailText: string,
}

function ServerTierColorBlue(props: Props) {
  return (
    ServerTierBase({
      color: ServerTierColorBlue.color,
      colorName: "Azul",
      description: ServerTierColorBlue.description,
      showColor: props.showColor,
      showDescription: props.showDescription,
      tailText: props.tailText
    })
  );
}

ServerTierColorBlue.description = "Local tranquilo sem dificuldades para acesso"
ServerTierColorBlue.color = "#4692EF"

ServerTierColorBlue.defaultProps = {
  showColor: true,
  showDescription: false,
  tailText: "",
}

export default ServerTierColorBlue;